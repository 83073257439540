import { Component, ComponentRef, ElementRef, inject, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-quotes',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './quotes.component.html',
  styleUrl: './quotes.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class QuotesComponent {
}
