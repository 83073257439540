import { inject, Injectable, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompositeQuoteItem, FinishedGoodQuoteItem, ItemType, PartQuoteItem, Quote, QuoteItem } from '../quotes/shared/models'
import { ApiGatewayService } from '../../core/api-gateway.service';
import { IConfiguratorPart, IQuote, IQuoteFormGroup, IQuoteItem, IQuoteItemFormGroup, IQuoteListView } from '../quotes/shared/interfaces';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Decimal } from 'decimal.js';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  private fb:FormBuilder = inject(FormBuilder);
  private gateway: ApiGatewayService = inject(ApiGatewayService);

  private quote: Quote;
  private quotefg!: FormGroup<IQuoteFormGroup>;

  private partFormGroupContextStack: FormArray<FormGroup<IQuoteItemFormGroup>>[] = [];
  private partContextStack: QuoteItem[] = [];

  activeFinishedGood: Subject<FormGroup<IQuoteItemFormGroup>> = new Subject();

  constructor() {
    this.quote = new Quote();
  }

  loadquotes(): Observable<IQuoteListView[]> {
    return this.gateway.get<IQuoteListView[]>('/quotes');
  }

  storeQuote(quote: IQuoteFormGroup) {
    return this.gateway.post<IQuoteFormGroup, any>('/quotes', quote);
  }

  updateQuote(quote: IQuoteFormGroup) {
    return this.gateway.post<IQuoteFormGroup, any>('/quotes/edit', quote);
  }

  getQuote(quote_id: string): Observable<IQuote> {
    return this.gateway.get<IQuote>('/quotes/' + quote_id);
  }

  makeQuoteFormGroup(quote: IQuote | undefined=undefined) {
    let dtm_quote: Date | string = '';
    if (quote?.dtm_quote) {
      dtm_quote = new Date(quote.dtm_quote);
    }

    this.quotefg = this.fb.group<IQuoteFormGroup>({
      quote_id: this.fb.control<string|null>(quote?.quote_id || ''),
      quote_ref: this.fb.control<string|null>(quote?.quote_ref || '', {nonNullable: true, validators: [Validators.required]}),
      description: this.fb.control<string|null>(quote?.description || '', {nonNullable: true, validators: [Validators.required]}),
      paragraph: this.fb.control<string|null>(quote?.paragraph || ''),
      customer_id: this.fb.control<string|null>(quote?.customer_id || ''),
      consultant: this.fb.control<string|null>(quote?.consultant || ''),
      attention: this.fb.control<string|null>(quote?.attention || ''),
      salutation: this.fb.control<string|null>(quote?.salutation || ''),
      dtm_quote: this.fb.control<Date | string | null>(dtm_quote),
      quote_items: this.fb.array<FormGroup<IQuoteItemFormGroup>>([])
    });

    this.quotefg.controls.quote_items.valueChanges.subscribe(quoteItems => {
      let idstokeep = [];
      for (let item of quoteItems) {
        if (item.item_part_id) {
          idstokeep.push(item.item_part_id);
        }
      }

      this.quote.quoteItems = this.quote.quoteItems.filter((part) => {
        if (part instanceof PartQuoteItem) {
          return idstokeep.includes(part.part_id);
        }

        if (part instanceof FinishedGoodQuoteItem) {
          return idstokeep.includes(part.id);
        }

        return true;
      });

    });

    this.partFormGroupContextStack.push(this.quotefg.controls.quote_items);
    if (quote?.quote_items) {
      this.populateItems(quote.quote_item_hierarchy);
    }
    return this.quotefg;
  }

  populateItems(items: IQuoteItem[]) {
    for (let quoteItem of items) {
      if (quoteItem.quote_item_id) {
        if (quoteItem.item_part_type == 'finished good') {
          this.addFinishedGood(quoteItem);
          this.doneFinishedGood();
        }

        if (quoteItem.item_part_type == ItemType.PART) {
          this.addPart(quoteItem);
        }
      }
    }
  }

  makeQuoteItem(item: Partial<IConfiguratorPart>, insertAt: number | undefined = undefined) {
    let quoteItem: Partial<IQuoteItem> = {
      'quote_item_part_id': item.id || undefined,
      'description': item.description || '',
      'price': item.part_price || 0,
      'quantity': item.quantity || 0,
      'markup': item.markup || 0
    }

    if (item.parts && item.parts.length) {
      quoteItem.parts = [];
      for (let itemSubpart of item.parts) {
        let subpart = {
          'quote_item_part_id': itemSubpart.id || undefined,
          'description': itemSubpart.description,
          'price': itemSubpart.part_price,
          'quantity': itemSubpart.quantity
        }

        quoteItem.parts.push(subpart);
      }
    }

    if (item.type == ItemType.PART) {
      this.addPart(quoteItem, insertAt);
    }

    if (item.type == ItemType.COMPOSITE) {
      this.addComposite(quoteItem, insertAt);
    }

    if (item.type == ItemType.FINISHEDGOOD) {
      this.addFinishedGood(quoteItem, insertAt);
    }
  }

  addPart(part: Partial<IQuoteItem>,
          insertAt: number | undefined = undefined) {
    let item = new PartQuoteItem(part);
    let context = this.peekPartContextStack();

    this.quote.addQuoteItem(item, context);

    let fg = item.formGroup;
    fg.controls.unit_price?.valueChanges.subscribe(newValue =>{
      item.price = newValue;
    });
    fg.controls.quantity.valueChanges.subscribe(newValue => {
      item.quantity = newValue;
    });
    fg.controls.markup?.valueChanges.subscribe(newValue => {
      item.markup = newValue;
    });

    if (insertAt || insertAt === 0) {
      this.peekPartFormGroup().insert(insertAt, fg);
    } else {
      this.peekPartFormGroup().push(fg);
    }
  }

  addComposite(composite: Partial<IQuoteItem>,
               insertAt: number|undefined = undefined) {
    let item = new CompositeQuoteItem(composite);
    let context = this.peekPartContextStack();
    this.quote.addQuoteItem(item, context);

    let fg = item.formGroup;
    if (insertAt || insertAt === 0) {
      this.peekPartFormGroup().insert(insertAt, fg);
    } else {
      this.peekPartFormGroup().push(fg);
    }

    if (fg.controls.parts) {
      this.pushPartFormGroup(fg.controls.parts);
      this.pushPartContextStack(item);

      if (composite.parts?.length) {
        for (let part of composite.parts) {
          this.addPart(part);
        }
      }

      fg.controls.parts.valueChanges.subscribe(parts => {
        if (parts.length < item.parts.length) {
          let idstokeep = [];
          for (let part of parts) {
            idstokeep.push(part.item_part_id);
          }
          item.parts = item.parts.filter((part) => {
            return idstokeep.includes(part.part_id);
          });
        }
      });
    }
  }

  addFinishedGood(finishedGood: Partial<IQuoteItem>,
                  insertAt: number | undefined = undefined) {
    let item = new FinishedGoodQuoteItem(finishedGood);
    this.quote.addQuoteItem(item);

    let fg = item.formGroup;
    this.peekPartFormGroup().push(fg);
    this.pushPartContextStack(item);

    if (fg.controls.parts) {
      this.pushPartFormGroup(fg.controls.parts);
    }

    if (finishedGood.parts?.length) {
      for (let fgitem of finishedGood.parts) {
        if (fgitem.item_part_type == ItemType.PART) {
          this.addPart(fgitem);
        }

        if (fgitem.item_part_type == ItemType.COMPOSITE) {
          this.addComposite(fgitem);
          this.doneComposite();
        }
      }
    }

    fg.controls.parts?.valueChanges.subscribe(parts => {
      if (item.parts) {
      if (parts.length < item.parts?.length) {
          let idstokeep = [];
          for (let part of parts) {
            if (part.item_part_id) {
              idstokeep.push(part.item_part_id);
            }

            if (part.item_composite_id) {
              idstokeep.push(part.item_composite_id);
            }
          }

          item.parts = item.parts.filter((part) => {
            if (part instanceof PartQuoteItem) {
              return idstokeep.includes(part.part_id)
            }

            if (part instanceof CompositeQuoteItem) {
              return idstokeep.includes(part.composite_id);
            }

            return true;
          });
        }
      }
    });

    this.activeFinishedGood.next(fg);
  }

  editFinishedGood(item: FormGroup<IQuoteItemFormGroup>) {
    if (item.controls.parts) {
      this.pushPartFormGroup(item.controls.parts);
      let quoteItem: IQuoteItem|undefined = this.quote.getQuoteItemAtLine(
        item.controls.item_line_number.value);
      if (quoteItem) {
        this.pushPartContextStack(quoteItem as QuoteItem);
      }
      this.activeFinishedGood.next(item);
    }
  }

  editComposite(item: FormGroup<IQuoteItemFormGroup>) {
    if (item.controls.parts) {
      this.pushPartFormGroup(item.controls.parts);
      let quoteItem: IQuoteItem|undefined = this.quote.getQuoteItemAtLine(
        item.controls.item_line_number.value);
      if (quoteItem) {
        this.pushPartContextStack(quoteItem as QuoteItem);
      }
    }
  }

  setCurrentLineNumber(line_number: number) {
    this.quote.setLineNumber(line_number);
  }

  doneComposite() {
    this.popPartFormGroup();
    this.popPartContextStack();
  }

  doneFinishedGood() {
    this.popPartFormGroup();
    this.popPartContextStack();
  }

  grandTotal() {
    return this.quote.grandTotal;
  }

  grandTotalIncMarkup() {
    return this.quote.grandTotalIncMarkup;
  }

  pushPartContextStack(item: QuoteItem) {
    this.partContextStack.push(item);
  }

  peekPartContextStack(): QuoteItem | undefined {
    if (this.partContextStack.length) {
      return this.partContextStack[this.partContextStack.length - 1];
    }

    return undefined;
  }

  popPartContextStack() {
    if (this.partContextStack.length) {
      this.partContextStack.pop();
    }
  }

  pushPartFormGroup(itemsFormGroup: FormArray<FormGroup<IQuoteItemFormGroup>>) {
    if (itemsFormGroup) {
      this.partFormGroupContextStack.push(itemsFormGroup);
    }
  }

  peekPartFormGroup() {
    return this.partFormGroupContextStack[this.partFormGroupContextStack.length - 1];
  }

  popPartFormGroup() {
    if (this.partFormGroupContextStack.length) {
      this.partFormGroupContextStack.pop();
    }
  }

  clearStacks() {
    this.partFormGroupContextStack = [];
    this.partContextStack = [];
    this.partFormGroupContextStack.push(this.quotefg.controls.quote_items);
  }
}
