<div class="address-form data-form" [formGroup]="addressFormGroup">
  <mat-form-field>
    <mat-label>Address Line 1</mat-label>
    <input matInput formControlName="address_line1" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Address Line 2</mat-label>
    <input matInput formControlName="address_line2" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Address Line 3</mat-label>
    <input matInput formControlName="address_line3" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>City</mat-label>
    <input matInput formControlName="city" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>County</mat-label>
    <input matInput formControlName="county" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Postal Code</mat-label>
    <input matInput formControlName="postal_code" />
  </mat-form-field>
</div>
