import { InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';


export interface ApiConfig {
    url: string;
    options: {};
}

export interface ApiResponse {
    msg: string;
}

export const API_CONFIG =
    new InjectionToken<ApiConfig>('tull.api.conf');


export const TULL_API_CONFIG:ApiConfig = {
    'url': environment.endpoint,
    'options': {
        'headers': {
            'Content-Type': 'application/json'
        },
        'responseType': 'json',
        'withCredentials': true
    }
}
