import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';
import { UserProfile } from './interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private gateway: ApiGatewayService = inject(ApiGatewayService);
  private _profile!: UserProfile;
  userprofile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>({} as UserProfile);

  constructor() {
    this.loadProfile();
  }

  get username(): string {
    return this._profile.username || '';
  }

  get group(): string {
    return this._profile.group || '';
  }

  loadProfile() {
    this.gateway.get<UserProfile>('/me').subscribe(profile => {
      this._profile = profile;
      this.userprofile$.next(profile);
    });
  }
}
