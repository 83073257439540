import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, effect, EventEmitter, inject, input, Input, model, Output, Signal, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QuoteService } from '../shared/quote.service';
import { StreamingService } from '../../core/streaming.service';

@Component({
  selector: 'app-configurator',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    ScrollingModule,
    MatFormFieldModule,
    FormsModule
  ],
  templateUrl: './configurator.component.html',
  styleUrl: './configurator.component.scss'
})
export class ConfiguratorComponent {
  private service: StreamingService = inject(StreamingService);

  parts: any[] = [];
  viewportParts = signal<any[]>([]);
  partGroups = signal<string[]>([]);
  partManufacturers = signal<string[]>([]);

  filterGroup: string = "";
  filterManufacturer: string = "";
  searchQuery: string = "";
  queryParts: any[] = [];

  missingBuffer: string = '';
  finishedGoodMode: boolean = false;
  finishedGoodButtonLabel: string = "Add";
  activateFinishedGood = input(false);
  reset = model(false);
  compositeMode: boolean = false;


  @Input() finishedGoodName: string = 'Finished Good';
  compositeName: string = 'Composite';
  activateCompositeMode = input(false);

  @Input() showFinishedGood: boolean = true;
  @Input() showComposite: boolean = true;
  @Input() showPartSelector: boolean = true;
  @Input() showPartFilter: boolean = true;

  @Output() partSelected: EventEmitter<any> = new EventEmitter();
  @Output() startFinishedGood: EventEmitter<any> = new EventEmitter();
  @Output() stopFinishedGood: EventEmitter<any> = new EventEmitter();
  @Output() stopComposite: EventEmitter<any> = new EventEmitter();

  constructor() {
    effect(() => {
      if (this.activateFinishedGood()) {
        this.toggleEditFinishedGoodMode();
      }
    });

    effect(() => {
      if (this.activateCompositeMode()) {
        this.toggleCompositeMode();
      }
    });

    effect(() => {
      if (this.reset()) {
        this.resetConfigurator();
      }
    }, {allowSignalWrites: true});
  }

  ngOnInit() {
    this.getParts();
  }

  clearall() {
    this.filterGroup = "";
    this.filterManufacturer = "";
    this.searchQuery = "";
    this.viewportParts.set(this.parts);
  }

  ngAfterViewInit(): void {
    window.document.getElementById("filters")?.addEventListener("click", (e) => {
      let filteredParts = []

      let element = e.target as HTMLSpanElement;
      window.document.getElementById(element.id);
      let filters = window.document.getElementsByClassName("filter-text");
      for (let i = 0; i < filters.length; i++) {
        let filter= filters[i];
        filter.removeAttribute("selected");
      }

      element.setAttribute("selected", "");

      if (element.textContent == 'Parts') {
        filteredParts = this.parts.filter(x => {
          return x.type == 'part';
        });
      }

      if (element.textContent == 'Composites') {
        filteredParts = this.parts.filter(x => {
          return x.type == 'composite';
        });
      }

      if (element.textContent == 'All') {
        this.viewportParts.set(this.parts);
        return;
      }

      this.viewportParts.set(filteredParts);
    });
  }

  search() {
    if (this.searchQuery == '') {
      return;
    }

    const url = this.service.makeRequest("search/" + encodeURIComponent(this.searchQuery));
    const req = new Request(url);
    fetch(req, {credentials: "include"})
    .then((response) => {
      const reader = response.body?.getReader();
      var that = this;
      return new ReadableStream({
        start(controller) {
          return readChunk();
          function readChunk(this: any): any {
            return reader?.read().then(({done, value}) => {
              if (done) {
                controller.close();
                that.viewportParts.set(that.queryParts);
                that.queryParts = [];
                /*that.parts.map((x) => {
                  if (that.partGroups().indexOf(x.part_group_ref) < 0) {
                    that.partGroups().push(x.part_group_ref);
                  }

                  if (that.partManufacturers().indexOf(x.part_manufacturer) < 0) {
                    that.partManufacturers().push(x.part_manufacturer);
                  }
                });*/
                return;
              }

              const decoder = new TextDecoder();
              let resp = decoder.decode(value);
              let partsChunk = that.appendParts(resp);
              that.queryParts = that.queryParts.concat(partsChunk);
              return readChunk();
            });
          }
        }
      });
    });
  }

  filterByGroup() {
    if (this.filterGroup == "") {
      this.viewportParts.set(this.parts);
      return;
    }

    this.viewportParts.set(this.parts.filter(part => {
      return part.part_group_ref == this.filterGroup;
    }));
  }

  filterByManufacturer() {
    if (this.filterManufacturer == "") {
      this.viewportParts.set(this.parts);
      return;
    }

    this.viewportParts.set(this.parts.filter(part => {
      return part.part_manufacturer == this.filterManufacturer;
    }));
  }

  getParts() {
    const url = this.service.makeRequest("stream");
    const req = new Request(url);
    fetch(req, {credentials: "include"})
    .then((response) => {
      let that = this;
      const reader = response.body?.getReader();
      return new ReadableStream({
        start(controller) {
          return readChunk();
          function readChunk(): any {
            return reader?.read().then(({done, value}) => {
              if (done) {
                controller.close();
                that.viewportParts.set(that.parts);
                that.parts.map((x) => {
                  if (that.partGroups().indexOf(that.capitalise(x.part_group_ref)) < 0) {
                    that.partGroups().push(that.capitalise(x.part_group_ref));
                  }

                  if (that.partManufacturers().indexOf(that.capitalise(x.part_manufacturer)) < 0) {
                    that.partManufacturers().push(that.capitalise(x.part_manufacturer));
                  }
                });
                return;
              }

              const decoder = new TextDecoder();
              let resp = decoder.decode(value);
              let partsChunk = that.appendParts(resp);
              if (partsChunk.length) {
                that.parts = that.parts.concat(partsChunk);
              }
              return readChunk();
            });
          }
        }
      })
    });
  }

  appendParts(parts: any) {
    let container: any[] = [];
    let split_parts = parts.split("\r\n");

    if (split_parts.length > 0) {
      let num_chunks = split_parts.length;

      for (let i = 0; i < num_chunks; i++) {
        if (split_parts[i] == "") continue;

        if (split_parts[i].at(-1) != "]") {
          this.missingBuffer += split_parts[i];
          continue;
        }

        if (split_parts[i].charAt(0) != "[") {
          if (this.missingBuffer != "") {
            if (split_parts[i].at(-1) == "]") {
              this.missingBuffer += split_parts[i];
              container = container.concat(JSON.parse(this.missingBuffer));
              this.missingBuffer = "";
              continue;
            }
          }

          this.missingBuffer += split_parts[i];
          continue;
        }

        if (split_parts[i] != "") {
          container = container.concat(JSON.parse(split_parts[i]));
        }
      }
    }

    return container;
  }

  isPartActive(part: any): boolean {
    if (part.type == 'composite') {
      if (this.finishedGoodMode) {
        if (this.compositeMode) {
          return false;
        }

        return true;
      }

      return false;
    }

    return true;
  }

  selectPart(event: any, part: any) {
    if (part.type == 'composite') {
      this.toggleCompositeMode();
    }
    this.partSelected.emit(part);
  }

  toggleCompositeMode() {
    if (!this.compositeMode) {
      this.beginComposite();
    } else {
      this.endComposite();
    }

    this.compositeMode = !this.compositeMode;
  }

  beginComposite() {
    return;
  }

  endComposite() {
    this.stopComposite.emit();
  }

  toggleEditFinishedGoodMode() {
    if (!this.finishedGoodMode) {
      this.finishedGoodButtonLabel = 'Done';
    } else {
      this.endFinishedGood();
      this.finishedGoodButtonLabel = 'Add';
    }

    this.finishedGoodMode = !this.finishedGoodMode;

  }

  toggleFinishedGoodMode() {
    if (!this.finishedGoodMode) {
      this.beginFinishedGood();
      this.finishedGoodButtonLabel = 'Done';
    } else {
      this.endFinishedGood();
      this.finishedGoodButtonLabel = 'Add';
    }

    this.finishedGoodMode = !this.finishedGoodMode;
  }

  beginFinishedGood() {
    this.startFinishedGood.emit();
  }

  endFinishedGood() {
    this.stopFinishedGood.emit();
  }

  resetConfigurator() {
    this.compositeMode = false;
    this.finishedGoodMode = false;
    this.endComposite();
    this.endFinishedGood();
    this.finishedGoodButtonLabel = 'Add';
    this.reset.set(false);
  }

  capitalise(name: string) {
    if (name == "") {
      return "None";
    }
    return name[0].toUpperCase() + name.slice(1)
  }
}
