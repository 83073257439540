import { Component } from '@angular/core';
import { PartHeaderComponent } from '../../part-header/part-header.component';

@Component({
  selector: 'app-part-configuration-list',
  standalone: true,
  imports: [
    PartHeaderComponent
  ],
  templateUrl: './part-configuration-list.component.html',
  styleUrl: './part-configuration-list.component.scss'
})
export class PartConfigurationListComponent {

}
