import { Component } from '@angular/core';
import { UserHeaderComponent } from '../../user-header/user-header.component';

@Component({
  selector: 'app-usergroup-list',
  standalone: true,
  imports: [
    UserHeaderComponent
  ],
  templateUrl: './usergroup-list.component.html',
  styleUrl: './usergroup-list.component.scss'
})
export class UsergroupListComponent {

}
