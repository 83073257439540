import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR, NgControl, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { UserService } from '../../core/user.service';
import { UserGroup } from '../../users/shared/models';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export class TullErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl |
               null, form: FormGroupDirective | NgForm | null) : boolean {
    const isSubmitted = form && form.submitted;

    return !!(control && control.invalid && (control.dirty || control.touched ||
                                             isSubmitted));
  }
}

@Component({
  selector: 'app-select-user-group',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  /*providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectUserGroupComponent
    }
  ],*/
  templateUrl: './select-user-group.component.html',
  styleUrl: './select-user-group.component.scss'
})
export class SelectUserGroupComponent implements ControlValueAccessor, OnInit {
  service: UserService = inject(UserService);
  matcher = new TullErrorStateMatcher();

  disabled: boolean = false;
  @Input() label: string = '';

  @ViewChild('userGroupSelect', {static: true}) userGroupSelect!: MatSelect;

  userGroups: UserGroup[] = [];

  constructor(@Self() @Optional() private control: NgControl) {
    this.control.valueAccessor = this;
  }

  ngOnInit() {
    this.service.getUserGroups().subscribe((groups) => {
      this.userGroups = groups;
    });

    this.userGroupSelect.selectionChange.subscribe((val: any) => {
      this.onChange(val.value);
    });
  }

  onChange: (newvalue: string) => void = (value) => {};
  onTouched: () => void = () => {};

  writeValue(obj: any): void {
      this.userGroupSelect.writeValue(obj);
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }
}
