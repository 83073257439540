import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';
import { User, UserGroup } from '../users/shared/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private gateway: ApiGatewayService = inject(ApiGatewayService);

  constructor() { }

  getUsers() {
    return this.gateway.get<any>('/users');
  }

  getUser(username: string) {
    return this.gateway.get<User>('/users/' + username);
  }

  updateUser(updatedUser: User) {
    return this.gateway.post<User, any>('/users/update', updatedUser);
  }

  createUser(newUser: User) {
    return this.gateway.post<User, any>('/users', newUser);
  }

  getUserGroups() {
    return this.gateway.get<UserGroup[]>('/users/groups');
  }

  checkEmail(email: string) {
    return this.gateway.get<any>('/users/emails/check/' + email);
  }
}

