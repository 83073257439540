<div id="configurator">
  <div id="finished-good" *ngIf="showFinishedGood">
    <h4 class="section-header">Finished Goods</h4>
    <div id="configurator-finished-good">
      <button mat-raised-button
          [disabled]="compositeMode"
          type="button"
          (click)="toggleFinishedGoodMode()">{{ finishedGoodButtonLabel }}</button>

      <mat-slide-toggle
          [disabled]="compositeMode"
          [checked]="finishedGoodMode"
          (toggleChange)="toggleFinishedGoodMode()">
        <em *ngIf="finishedGoodMode" style="display: inline-block; max-width: 100px;">Editting: {{ finishedGoodName }}</em>
      </mat-slide-toggle>
    </div>
  </div>

  <div id="composite" *ngIf="compositeMode && showComposite">
    <h4 class="section-header">Composite</h4>
    <div id="configurator-finished-good">
      <mat-slide-toggle
          [checked]="compositeMode"
          (toggleChange)="toggleCompositeMode()">
        <em *ngIf="compositeMode">Editting {{ compositeName }}</em>
      </mat-slide-toggle>
    </div>
  </div>

  <div id="part-configuration" *ngIf="showPartSelector">
    <div class="section-header section-header-with-action">
      <h4>Part Selector</h4>
      <button mat-icon-button
        type="button" (click)="clearall()">
        <mat-icon>cleaning_services</mat-icon>
      </button>
    </div>
    <div id="search-console">
      <form>
        <div class="input-container console-row">
          <div class="console-row-item search-input">
            <input type="text" [(ngModel)]="searchQuery" name="search_query"/>
          </div>
          <div>
            <button type="button" (click)="search()">Search</button>
          </div>
        </div>

        <div id="facets" class="console-row">
          <div id="group" class="console-row-item">
            <select [(ngModel)]="filterGroup" (change)="filterByGroup()" name="part_group">
              <option value="" selected="selected">Select Group ...</option>
              @for (p of partGroups().sort(); track p) {
               <option value="{{ p }}">{{ p }}</option>
              }
            </select>
          </div>

          <div id="manufacturer" class="console-row-item">
            <select [(ngModel)]="filterManufacturer" (change)="filterByManufacturer()" name="part_manufacturer">
              <option value="" selected="selected">Manufacturer</option>
              @for (p of partManufacturers().sort(); track p) {
               <option value="{{ p }}">{{ p }}</option>
              }
            </select>
          </div>
        </div>

        <div id="filter-row" class="console-row" *ngIf="showPartFilter">
          <div id="filters">
            <div class="search-filter">
              <span id="all" selected class="filter-text">All</span>
            </div>
            <div class="search-filter">
              <span id="parts" class="filter-text">Parts</span>
            </div>
            <div class="search-filter">
              <span id="composites" class="filter-text">Composites</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <cdk-virtual-scroll-viewport itemSize="100" minBufferPx="500" maxBufferPx="900" id="part-selector">
      <div class="part" *cdkVirtualFor="let part of viewportParts()">
        <div class="header">
          <span class="action">
            <button (click)="selectPart($event, part)"
                  class="add"
                  [disabled]="!isPartActive(part)">+</button>
          </span>
          <h5 class="part-ref"><span *ngIf="(part.type == 'composite')">[C]&nbsp;</span>{{ part.ref }}</h5>
        </div>
        <span class="description">{{ part.description}}</span>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
