<div id="quote-grid-container">
<div id="quote-grid">
  <div class="configurator-column">
    <app-configurator (partSelected)="handlePartSelect($event)"
      [activateFinishedGood]="activateFinishedGoodMode"
      [activateCompositeMode]="activateCompositeMode"
      [finishedGoodName]="activeFinishedGoodDescription"
      [(reset)]="resetConfigurator"
      (startFinishedGood)="addFinishedGood()"
      (stopFinishedGood)="destroyFinishedGoodContext()"
      (stopComposite)="destroyCompositeContext()" style="position: sticky"></app-configurator>
  </div>

  <div id="quote" class="quote-column">
    <form [formGroup]="quoteFormGroup" (ngSubmit)="onSubmit()" *ngIf="quoteFormGroup">
      <div class="quote-details">
        <h4 class="section-header">Quote</h4>

        <div class="quote-header">
          <div class="quote-header-row">
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Quote Reference</mat-label>
                <input matInput formControlName="quote_ref" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput formControlName="description" />
              </mat-form-field>
            </div>
          </div>
          <div class="quote-header-row">
            <div class="quote-header-item">
              <app-selectcustomer formControlName="customer_id"></app-selectcustomer>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Paragraph</mat-label>
                <input matInput formControlName="paragraph" />
              </mat-form-field>
            </div>
          </div>
          <div class="quote-header-row">
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Consultant</mat-label>
                <input matInput formControlName="consultant" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Attn</mat-label>
                <input matInput formControlName="attention" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Salutation</mat-label>
                <input matInput formControlName="salutation" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="dtm_quote" />
                <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <table class="border-table">
        <tr>
          <th class="cell"></th>
          <th class="cell">Description</th>
          <th class="cell">Qty</th>
          <th class="cell">Unit Price</th>
          <th class="cell">Markup</th>
          <th class="cell">Total</th>
          <th class="cell">Total inc. Markup</th>
        </tr>
        <!--<div class="quote-item-style solid-underline"></div>-->
        <tbody #dragBoundary
          cdkDropList
          (cdkDropListDropped)="drop($event, quoteItems)"
          [cdkDropListData]="quoteItems"
          cdkDropListLockAxis="y"
          class="quote-drag-boundary">
          @for (item of quoteItems.controls; track item; let idx = $index) {
            @if (item.value.item_part_type == 'part') {
              <tr [formGroup]="item" cdkDrag cdkDragBoundary=".quote-drag-boundary" class="quote-item-row quote-item">
                <td class="cell action">
                  <span class="action-panel line-number" style="justify-content: flex-end">
                    <button mat-icon-button type="button"
                      (click)="setInsertionPoint(item.value)">
                      <mat-icon class="material-symbols-outlined"
                        [ngClass]="isInsertionPointActive(item.value.item_line_number) ? 'insert-at-active' : 'insert-at'">
                        format_paragraph
                      </mat-icon>
                    </button>
                    <span class="number">{{ item.value.item_line_number }}</span>
                  </span>
                  <span class="action-panel" style="justify-content: space-between">
                    <mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
                    <button mat-icon-button type="button" (click)="deleteItemAt(idx, quoteItems)"><mat-icon>delete</mat-icon></button>
                  </span>
                </td>
                <td class="cell description">
                  <mat-form-field class="full-width-form-field">
                    <input matInput formControlName="quote_item_description" />
                  </mat-form-field>
                </td>
                <td class="cell right-align">
                  <mat-form-field class="full-width-form-field">
                    <input type="number" step="1" matInput formControlName="quantity" />
                  </mat-form-field>
                </td>
                <td class="cell right-align">
                  <mat-form-field class="full-width-form-field">
                    <input matInput formControlName="unit_price" />
                  </mat-form-field>
                </td>
                <td class="cell right-align">
                  <mat-form-field class="full-width-form-field">
                    <input type="number" step="0.5" matInput formControlName="markup" />
                  </mat-form-field>
                </td>
                <td class="cell right-align total">{{  quoteItemTotal(item) }}</td>
                <td class="cell right-align total">{{  quoteItemTotalIncMarkup(item) }}</td>
              </tr>
            }

            @if (item.value.item_part_type == 'finished_good') {
            <tr cdkDrag class="quote-item-row quote-item">
              <td colspan="7" style="padding: 0;">
                <table class="finished-good active" [formGroup]="item">
                  <tr class="quote-item quote-item-row">
                    <td class="cell action">
                      <span class="action-panel line-number" style="justify-content: flex-end">
                        <button mat-icon-button type="button"
                          (click)="setInsertionPoint(item.value)">
                          <mat-icon class="material-symbols-outlined"
                            [ngClass]="isInsertionPointActive(item.value.item_line_number) ? 'insert-at-active' : 'insert-at'">
                            format_paragraph
                          </mat-icon>
                        </button>
                        <span class="number">{{ item.value.item_line_number }}</span>
                      </span>
                      <span class="action-panel">
                        <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>
                        <button mat-icon-button type="button" *ngIf="!inFinishedGoodContext" (click)="editFinishedGood(item, true)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button type="button" (click)="deleteItemAt(idx, quoteItems)"><mat-icon>delete</mat-icon></button>
                      </span>
                      <span class="action-panel">
                        <span class="placeholder"></span>
                        <button mat-icon-button type="button" (click)="pasteItem(item)" *ngIf="canPaste(item.value)"><mat-icon>content_paste</mat-icon></button>
                        <button mat-icon-button type="button" (click)="copyItem(item)" *ngIf="canCopy"><mat-icon>file_copy</mat-icon></button>
                      </span>
                    </td>
                    <td class="cell description">
                      <mat-form-field class="full-width-form-field">
                        <input matInput formControlName="quote_item_description" />
                      </mat-form-field>
                    </td>
                    <td class="cell right-align"></td>
                    <td class="cell right-align"></td>
                    <td class="cell right-align"></td>
                    <td class="cell right-align total">{{ quoteItemAggTotal(item.controls.parts) }}</td>
                    <td class="cell right-align">{{ quoteItemAggTotalIncMarkup(item.controls.parts) }}</td>
                  </tr>
                  <tbody cdkDropList
                    (cdkDropListDropped)="drop($event, item.controls.parts)"
                    class="fg-parts finished-good-drag-boundary">
                  @for (fg_item of getcontrols(item.controls.parts); track fg_item; let idx = $index) {
                    @if (fg_item.value.item_part_type == 'part') {
                      <tr [formGroup]="fg_item" cdkDrag cdkDragBoundary=".finished-good-drag-boundary" class="quote-item-row">
                        <td class="cell action">
                          <span class="action-panel line-number" style="justify-content: flex-end">
                            <button mat-icon-button type="button"
                              (click)="setInsertionPoint(fg_item.value)">
                              <mat-icon class="material-symbols-outlined"
                                [ngClass]="isInsertionPointActive(fg_item.value.item_line_number) ? 'insert-at-active' : 'insert-at'">
                                format_paragraph
                              </mat-icon>
                            </button>
                            <span class="number">{{ fg_item.value.item_line_number }}</span>
                          </span>
                          <span class="action-panel" style="justify-content: space-between">
                            <mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
                            <button mat-icon-button type="button" (click)="deleteItemAt(idx, item.controls.parts)"><mat-icon>delete</mat-icon></button>
                          </span>
                        </td>
                        <td class="cell description">
                          <mat-form-field class="full-width-form-field">
                            <input matInput formControlName="quote_item_description" />
                          </mat-form-field>
                        </td>
                        <td class="cell right-align right-align">
                          <mat-form-field class="full-width-form-field">
                            <input type="number" step="1" matInput formControlName="quantity" />
                          </mat-form-field>
                        </td>
                        <td class="cell right-align">
                          <mat-form-field class="full-width-form-field">
                            <input matInput formControlName="unit_price" />
                          </mat-form-field>
                        </td>
                        <td class="cell right-align">
                          <mat-form-field class="full-width-form-field">
                            <input type="number" step="0.5" matInput formControlName="markup" />
                          </mat-form-field>
                        </td>
                        <td class="cell right-align">{{  quoteItemTotal(fg_item) }}</td>
                        <td class="cell right-align">{{  quoteItemTotalIncMarkup(fg_item) }}</td>
                      </tr>
                    }

                    @if (fg_item.value.item_part_type == 'composite') {
                    <tr cdkDrag class="quote-item-row quote-item">
                      <td colspan="7" style="padding: 0;">
                        <table class="composite active" [formGroup]="fg_item">
                          <tr class="quote-item quote-item-row">
                            <td class="cell action">
                              <span class="action-panel line-number" style="justify-content: flex-end">
                                <button mat-icon-button type="button"
                                  (click)="setInsertionPoint(fg_item.value)">
                                  <mat-icon class="material-symbols-outlined"
                                    [ngClass]="isInsertionPointActive(fg_item.value.item_line_number) ? 'insert-at-active' : 'insert-at'">
                                    format_paragraph
                                  </mat-icon>
                                </button>
                                <span class="number">{{ fg_item.value.item_line_number }}</span>
                              </span>
                              <span class="action-panel">
                                <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>
                                <button mat-icon-button type="button" *ngIf="inFinishedGoodContext && !inCompositeContext" (click)="editComposite(fg_item, true)"><mat-icon>edit</mat-icon></button>
                                <button mat-icon-button type="button" (click)="deleteItemAt(idx, item.controls.parts)"><mat-icon>delete</mat-icon></button>
                              </span>
                              <span class="action-panel">
                                <span class="placeholder"></span>
                              </span>
                            </td>
                            <td class="cell description">
                              <mat-form-field class="full-width-form-field">
                                <input matInput formControlName="quote_item_description" />
                              </mat-form-field>
                            </td>
                            <td class="cell right-align"></td>
                            <td class="cell right-align"></td>
                            <td class="cell right-align"></td>
                            <td class="cell right-align total">{{ quoteItemAggTotal(item.controls.parts) }}</td>
                            <td class="cell right-align">{{ quoteItemAggTotalIncMarkup(item.controls.parts) }}</td>
                          </tr>
                          <tbody cdkDropList
                            (cdkDropListDropped)="drop($event, item.controls.parts)"
                            class="composite-parts finished-good-drag-boundary">
                          @for (cmp_item of getcontrols(fg_item.controls.parts); track cmp_item; let idx = $index) {
                            @if (cmp_item.value.item_part_type == 'part') {
                              <tr [formGroup]="cmp_item" cdkDrag cdkDragBoundary=".finished-good-drag-boundary" class="quote-item-row">
                                <td class="cell action">
                                  <span class="action-panel line-number" style="justify-content: flex-end">
                                    <button mat-icon-button type="button"
                                      (click)="setInsertionPoint(cmp_item.value)">
                                      <mat-icon class="material-symbols-outlined"
                                        [ngClass]="isInsertionPointActive(cmp_item.value.item_line_number) ? 'insert-at-active' : 'insert-at'">
                                        format_paragraph
                                      </mat-icon>
                                    </button>
                                    <span class="number">{{ cmp_item.value.item_line_number }}</span>
                                  </span>
                                  <span class="action-panel" style="justify-content: space-between">
                                    <mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
                                    <button mat-icon-button type="button" (click)="deleteItemAt(idx, fg_item.controls.parts)"><mat-icon>delete</mat-icon></button>
                                  </span>
                                </td>
                                <td class="cell description">
                                  <mat-form-field class="full-width-form-field">
                                    <input matInput formControlName="quote_item_description" />
                                  </mat-form-field>
                                </td>
                                <td class="cell right-align right-align">
                                  <mat-form-field class="full-width-form-field">
                                    <input type="number" step="1" matInput formControlName="quantity" />
                                  </mat-form-field>
                                </td>
                                <td class="cell right-align">
                                  <mat-form-field class="full-width-form-field">
                                    <input matInput formControlName="unit_price" />
                                  </mat-form-field>
                                </td>
                                <td class="cell right-align">
                                  <mat-form-field class="full-width-form-field">
                                    <input type="number" step="0.5" matInput formControlName="markup" />
                                  </mat-form-field>
                                </td>
                                <td class="cell right-align">{{  quoteItemTotal(cmp_item) }}</td>
                                <td class="cell right-align">{{  quoteItemTotalIncMarkup(cmp_item) }}</td>
                              </tr>
                            }
                          }
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    }
                  }
                  </tbody>
                </table>
              </td>
            </tr>
            }
          }
        </tbody>

        <tr class="quote-item" *ngIf="grandTotal">
          <td class="cell"></td>
          <td class="cell"></td>
          <td class="cell"></td>
          <td class="cell"></td>
          <td class="cell"></td>
          <td class="cell right-align">{{ grandTotal }}</td>
          <td class="cell right-align">{{ grandTotalIncMarkup }}</td>
        </tr>
        <!--<div class="quote-item-style solid-underline"></div>-->
      </table>

      <div class="quote-action-panel">
        <button mat-raised-button>{{ buttonText }}</button>
      </div>
    </form>
  </div>
</div>
</div>
