import { CommonModule } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { AuthResponse, CheckAuthResponse } from '../core/models';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    }
  ]
})
export class LoginComponent {
  private fb: FormBuilder = inject(FormBuilder);
  private auth: AuthService = inject(AuthService);

  today = new Date();

  loginForm = this.fb.group({
      username: [''],
      password: ['']
  });

  loginError:boolean = false;
  loginErrorMsg:string = '';

  screenheight: number = 0;
  screenwidth: number = 0;
  mobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    if ( window.innerWidth == this.screenwidth ) {
      return;
    }

    this.screenheight = window.innerHeight;
    this.screenwidth = window.innerWidth;

    if (this.screenwidth <= 450) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngOnInit(): void {
      this.getScreenSize();

      this.auth.isLoggedIn().subscribe((resp: CheckAuthResponse) => {
        if (resp.isLoggedIn) {
          this.router.navigate(['/']);
        }
      });
  }

  constructor(private router:Router) {}

  authenticate() {
      const {username, password} = this.loginForm.value;
      this.auth.authenticate(username as string, password as string).subscribe({
          next: resp => {
              this.router.navigate(["/"]);
          },
          error: err => {
              this.loginError = true;
              this.loginErrorMsg = err.error.msg;
          }
      });
      return false;
  }

}
