import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IPartAccessoryFormGroup } from '../../shared/models';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-part-accessory-form-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule
  ],
  templateUrl: './part-accessory-form-group.component.html',
  styleUrl: './part-accessory-form-group.component.scss'
})
export class PartAccessoryFormGroupComponent {
  @Input() fg!: FormGroup<IPartAccessoryFormGroup>;
}
