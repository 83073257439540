<div id="cusomter-form" class="data-form">
  <form [formGroup]="customerFormGroup" (submit)="editCustomer()">
    <mat-form-field>
      <mat-label>Customer Name</mat-label>
      <input matInput formControlName="customer_name" />
    </mat-form-field>
    <div
        *ngIf="customer_name.invalid && (customer_name.dirty || customer_name.touched)"
        class="alert alert-danger">
      Customer name is required
    </div>

    <app-address *ngFor="let fg of addresses.controls" [addressFormGroup]="fg"></app-address>

    <div class="form-action form-action-right">
      <button mat-flat-button>{{ buttonText }}</button>
    </div>
  </form>
</div>
