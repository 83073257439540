import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from '../../core/api-gateway.service';
import { Observable } from 'rxjs';
import { IManufacturer, Part, IPartGroup, ISupplier, IPart, IPartFormGroup, IComposite } from './models';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PartService {
  private gateway: ApiGatewayService = inject(ApiGatewayService);

  constructor() { }

  getManufacturers(): Observable<IManufacturer[]> {
    return this.gateway.get<IManufacturer[]>('/manufacturers');
  }

  getPartGroups(): Observable<IPartGroup[]> {
    return this.gateway.get<IPartGroup[]>('/parts/groups');
  }

  getPart(part_id: string): Observable<IPart> {
    return this.gateway.get<IPart>('/parts/' + part_id);
  }

  getComposite(composite_id: string): Observable<IComposite> {
    return this.gateway.get<IComposite>('/composites/' + composite_id);
  }

  getSuppliers(): Observable<ISupplier[]> {
    return this.gateway.get<ISupplier[]>('/suppliers');
  }

  save(part: IPart) {
    let action = '/parts';
    if (part.part_id) {
      action = `${action}/edit`;
    }

    return this.gateway.post<IPart, any>(action, part);
  }

  saveComposite(composite: IComposite) {
    let action = '/composites';
    if (composite.composite_id) {
      action = `${action}/edit`;
    }

    return this.gateway.post<IComposite, any>(action, composite);
  }
}
