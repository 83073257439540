import { Component, inject, OnInit } from '@angular/core';
import { PartService } from '../shared/part.service';
import { CommonModule } from '@angular/common';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Composite, CompositePart, IComposite, ICompositeFormGroup, ICompositePartFormGroup } from '../shared/models';
import { ConfiguratorComponent } from '../../plm/configurator/configurator.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-composite-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    ConfiguratorComponent
  ],
  templateUrl: './composite-edit.component.html',
  styleUrl: './composite-edit.component.scss'
})
export class CompositeEditComponent implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  service: PartService = inject(PartService);
  composite!: Composite;
  compositeFormGroup!: FormGroup<ICompositeFormGroup>;
  descriptions: any = {};

  buttonText: string = 'Add';
  editMode: boolean = false;

  get compositeParts(): FormArray<FormGroup<ICompositePartFormGroup>> {
    return this.compositeFormGroup.controls.composite_parts as FormArray<FormGroup<ICompositePartFormGroup>>;
  }

  constructor() {}

  ngOnInit(): void {
    let composite_id = this.route.snapshot.params['id'] || undefined;

    if (composite_id) {
      this.service.getComposite(composite_id).subscribe(composite => {
        this.composite = new Composite(composite);
        this.compositeFormGroup = this.composite.formGroup;

        if (this.composite.composite_parts && this.composite.composite_parts.length) {
          for (let part of this.composite.composite_parts) {
            this.compositeParts.push(part.formGroup);
            this.descriptions[part.part_id] = part.description;
          }
        }

        this.setEditMode();
      })
    } else {
      this.composite = new Composite();
      this.compositeFormGroup = this.composite.formGroup;
    }

  }

  setEditMode() {
    this.editMode = true;
    this.buttonText = 'Update';
  }

  editComposite() {
    if (this.compositeFormGroup.valid) {
      this.service.saveComposite(this.compositeFormGroup.value as IComposite).subscribe(msg => {
        console.log(msg);
      });
    }
  }

  onPartSelect(event: any) {
    let newPart = new CompositePart();
    newPart.part_id = event.id;
    newPart.composite_id = this.composite.composite_id;
    newPart.position = this.compositeParts.length + 1;
    this.descriptions[newPart.part_id] = event.description;

    this.compositeParts.push(newPart.formGroup);
  }
}
