<div class="app-section-header">
  <div class="manager-panel app-section-header-item">
    <a mat-fab extended routerLink="/parts/add">
      <mat-icon>add</mat-icon>
      Add Part
    </a>
    <a mat-fab extended routerLink="/parts/composites/add">
      <mat-icon>add</mat-icon>
      Add Composite
    </a>
    <ng-content></ng-content>
  </div>
  <div class="app-section-header-item app-category-container">
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/parts/list">Parts</a>
    </div>
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/parts/manufacturer">Manufacturers</a>
    </div>
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/parts/supplier">Suppliers</a>
    </div>
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/parts/groups">Part Groups</a>
    </div>
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/parts/configurations">Part Configurations</a>
    </div>
  </div>
</div>
