import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IMarkup, IPart, IPartAccessoryFormGroup, IPartFormGroup, IPartMarkupFormGroup, IPartNoteFormGroup, IPartSupplierFormGroup, Part, PartAccessory, PartMarkup, PartNote, PartSupplier } from '../shared/models';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectManufacturerControlComponent } from '../../controls/select-manufacturer-control/select-manufacturer-control.component';
import { SelectPartGroupControlComponent } from '../../controls/select-part-group-control/select-part-group-control.component';
import { PartMarkupFormGroupComponent } from '../forms/part-markup-form-group/part-markup-form-group.component';
import { PartAccessoryFormGroupComponent } from '../forms/part-accessory-form-group/part-accessory-form-group.component';
import { PartNoteFormGroupComponent } from '../forms/part-note-form-group/part-note-form-group.component';
import { ActivatedRoute } from '@angular/router';
import { PartService } from '../shared/part.service';
import { SelectSupplierControlComponent } from '../../controls/select-supplier-control/select-supplier-control.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-part-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    SelectManufacturerControlComponent,
    SelectPartGroupControlComponent,
    PartMarkupFormGroupComponent,
    PartAccessoryFormGroupComponent,
    PartNoteFormGroupComponent,
    SelectSupplierControlComponent
  ],
  templateUrl: './part-edit.component.html',
  styleUrl: './part-edit.component.scss'
})
export class PartEditComponent implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private service: PartService = inject(PartService);
  private part!: Part;

  partFormGroup!: FormGroup<IPartFormGroup>;

  editMode: boolean = false;
  buttonText: string = 'Add';

  get markups(): FormArray<FormGroup<IPartMarkupFormGroup>> {
    return this.partFormGroup.controls.markups as FormArray<FormGroup<IPartMarkupFormGroup>>;
  }

  get accessories(): FormArray<FormGroup<IPartAccessoryFormGroup>> {
    return this.partFormGroup.controls.accessories as FormArray<FormGroup<IPartAccessoryFormGroup>>;
  }

  get notes(): FormArray<FormGroup<IPartNoteFormGroup>> {
    return this.partFormGroup.controls.notes as FormArray<FormGroup<IPartNoteFormGroup>>
  }

  get suppliers(): FormArray<FormGroup<IPartSupplierFormGroup>> {
    return this.partFormGroup.controls.suppliers as FormArray<FormGroup<IPartSupplierFormGroup>>;
  }

  constructor() {}

  ngOnInit(): void {
    let part_id = this.route.snapshot.params['id'] || undefined;
    if (part_id) {
      this.service.getPart(part_id).subscribe(partData => {
        this.part = new Part(partData);
        this.partFormGroup = this.part.formGroup;
        this.syncArrays();
        this.toggleEditMode();
      });
    } else {
      this.part = new Part();
      this.partFormGroup = this.part.formGroup;
    }
  }

  syncArrays() {
    for (let mu of this.part.markups) {
      this.markups.push(mu.formGroup);
    }

    for (let note of this.part.notes) {
      this.notes.push(note.formGroup);
    }

    for (let supplier of this.part.suppliers) {
      this.suppliers.push(supplier.formGroup);
    }

    for (let accessory of this.part.accessories) {
      this.accessories.push(accessory.formGroup);
    }
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.buttonText='Edit';
    } else {
      this.buttonText = 'Add';
    }
  }

  addMarkup() {
    let markup = new PartMarkup();
    markup.part_id = this.part.part_id;
    markup.markup_number = this.markups.length+1;
    this.markups.push(markup.formGroup);
  }

  addAccessory() {
    let accessory = new PartAccessory();
    accessory.part_id = this.part.part_id;
    this.accessories.push(accessory.formGroup);
  }

  addNote() {
    let note = new PartNote();
    note.part_id = this.part.part_id;
    this.notes.push(note.formGroup);
  }

  addSupplier() {
    let supplier = new PartSupplier()
    supplier.part_id = this.part.part_id;
    supplier.supplier_number = this.suppliers.length+1;
    this.suppliers.push(supplier.formGroup);
  }

  deleteAt(array: FormArray, index: number) {
    if (index < array.length) {
      array.removeAt(index);
    }
  }

  deleteMarkupAt(index: number) {
    this.markups.removeAt(index);
    let markup_number = 1;
    for (let markup of this.markups.controls) {
      markup.controls.markup_number.setValue(markup_number++);
    }
  }

  editPart() {
    this.service.save(this.partFormGroup.value as IPart).subscribe(msg => {
      console.log(msg);
    });
  }
}
