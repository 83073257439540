<div class="manager-panel">
  <a mat-fab extended [routerLink]="'/customers/add'">
    <mat-icon>add</mat-icon>
    Add Customer
  </a>
</div>


<div class="hyp-list">
  <div class="hyp-list-header hyp-list-row">
    <h4 class="hyp-list-item">Name</h4>
    <h4 class="hyp-list-item">Address Line 1</h4>
    <h4 class="hyp-list-item">City</h4>
    <h4 class="hyp-list-item">Postal Code</h4>
    <span class="action hyp-list-item"></span>
  </div>
  <div class="hyp-list-row" *ngFor="let customer of customers">
    <span class="hyp-list-item">{{ customer.customer_name }}</span>
    <span class="hyp-list-item">{{ customer.address_line1 }}</span>
    <span class="hyp-list-item">{{ customer.city }}</span>
    <span class="hyp-list-item">{{ customer.postal_code }}</span>
    <div class="action hyp-list-item">
      <a mat-icon-button [routerLink]="['/customers', customer.customer_id]"><mat-icon>edit</mat-icon></a>
    </div>
  </div>
</div>

