import { Component } from '@angular/core';

@Component({
  selector: 'app-part-configuration-edit',
  standalone: true,
  imports: [],
  templateUrl: './part-configuration-edit.component.html',
  styleUrl: './part-configuration-edit.component.scss'
})
export class PartConfigurationEditComponent {

}
