import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../core/auth.service';
import { AuthResponse } from '../../core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-nav',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatRippleModule,
    MatIconModule
  ],
  templateUrl: './top-nav.component.html',
  styleUrl: './top-nav.component.scss'
})
export class TopNavComponent implements OnInit {
  private service: AuthService = inject(AuthService);
  private router: Router = inject(Router);

  @Input() profile!: any;
  username!: string;

  ngOnInit(): void {
    if (this.profile) {
      this.username = this.profile.username;
    }
  }

  logout() {
    this.service.logout().subscribe((response: AuthResponse) => {
      if (!response.authenticated) {
        this.router.navigate(['/login']);
      }
    });
  }
}
