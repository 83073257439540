import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { PartService } from '../../parts/shared/part.service';
import { CommonModule } from '@angular/common';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISupplier } from '../../parts/shared/models';

@Component({
  selector: 'app-select-supplier-control',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectSupplierControlComponent
    }
  ],
  templateUrl: './select-supplier-control.component.html',
  styleUrl: './select-supplier-control.component.scss'
})
export class SelectSupplierControlComponent implements ControlValueAccessor, OnInit {
  protected service: PartService = inject(PartService);
  private disabled: boolean = false;
  @Input() label: string = '';

  @ViewChild('supplierSelect', {static: true}) supplierSelect!: MatSelect;

  suppliers!: ISupplier[];

  constructor() {}

  ngOnInit(): void {
      this.service.getSuppliers().subscribe(suppliers => {
        this.suppliers = suppliers;
      });

      this.supplierSelect.selectionChange.subscribe(val => {
        this.onChange(val.value);
      })
  }

  onChange: (newvalue: string) => void = (value) => {};
  onTouched: () => void = () => {};

  writeValue(obj: any): void {
    this.supplierSelect.writeValue(obj);
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }
}
