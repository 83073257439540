<div class="top-nav-container">
  <div matRipple id="top-nav--profile" [matMenuTriggerFor]="menu">
      <span class="material-symbols-outlined">account_circle</span>
      <span class="profile-username">{{ username }}</span>
      <span class="material-symbols-outlined arrow-down">arrow_drop_down</span>
  </div>
  <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Sign Out</span>
      </button>
  </mat-menu>
</div>
