import { Component } from '@angular/core';
import { PartHeaderComponent } from '../part-header/part-header.component';

@Component({
  selector: 'app-part-group',
  standalone: true,
  imports: [
    PartHeaderComponent
  ],
  templateUrl: './part-group.component.html',
  styleUrl: './part-group.component.scss'
})
export class PartGroupComponent {

}
