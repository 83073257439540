<table>
  <thead>
    <th>Group</th>
    <th>Total</th>
    <th>Total Inc. Markup</th>
  </thead>
  <tbody>
    <tr *ngFor="let summaryrow of summary">
      <td>{{ summaryrow.part_group_ref }}</td>
      <td class="price">{{ summaryrow.total }}</td>
      <td class="price">{{ summaryrow.totalincmarkup }}</td>
    </tr>
  </tbody>
</table>
