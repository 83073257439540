<div class="manager-panel">
  <a mat-fab extended [routerLink]="'/quotes/add'">
    <mat-icon>add</mat-icon>
    Add Quote
  </a>
</div>

<div class="hyp-list" *ngIf="quotes">
  <div id="user-header" class="hyp-list-row">
    <span class="hyp-action hyp-list-item"></span>
    <h4 class="hyp-list-item">Quote Reference</h4>
    <h4 class="hyp-list-item">Customer</h4>
    <h4 class="hyp-list-item">Sales Rep</h4>
    <h4 class="hyp-list-item">Date</h4>
    <span class="hyp-action hyp-list-item full-panel"></span>
  </div>

  <div *ngFor="let quote of quotes" class="hyp-list-column">
    <div class="hyp-list-row">
      <span class="hyp-action hyp-list-item small-action">
        <button mat-icon-button
          matTooltip="Hot Jobs"
          type="button"
          (click)="toggleHotJob(quote)">
          <mat-icon class="material-symbols-outlined" [ngClass]="quote.hot_job ? 'hot-job' : ''">mode_heat</mat-icon>
        </button>
      </span>
      <span class="hyp-list-item">{{ quote.quote_ref }}</span>
      <span class="hyp-list-item">{{ quote.customer_name }}</span>
      <span class="hyp-list-item">{{ quote.first_name }} {{ quote.surname }}</span>
      <span class="hyp-list-item">{{ quote.dtm_quote | date: 'dd/MM/YYYY' }}</span>
      <div class="hyp-action hyp-list-item full-panel">
        <div class="panel">
          <div class="action">
            <button mat-icon-button
              type="button"
              matTooltip="Quote Summary"
              (click)="toggleSummary(quote.quote_id)">
              <mat-icon class="material-symbols-outlined">read_more</mat-icon>
            </button>
          </div>
          <div class="action">
            <button mat-icon-button
              matTooltip="Detailed Breakdown"
              type="button">
              <mat-icon class="material-symbols-outlined">summarize</mat-icon>
            </button>
          </div>
          <div class="action">
            <button mat-icon-button
              matTooltip="PDF Customer Quote"
              type="button">
              <mat-icon class="material-symbols-outlined">download</mat-icon>
            </button>
          </div>
          <div class="action">
            <a mat-icon-button
              matTooltip="Edit"
              [routerLink]="['/quotes', quote.quote_id]"><mat-icon>edit</mat-icon></a>
          </div>
        </div>
      </div>
    </div>
    <div class="hyp-list-row"
      *ngIf="showSummary(quote.quote_id)"
      [ngClass]="showSummary(quote.quote_id) ? 'row-active': 'row-hidden'">
      <div class="hyp-list-item">
        <app-summary-chart [summary]="quote.summary"></app-summary-chart>
      </div>
      <div class="hyp-list-item">
        <app-summary-table [summary]="quote.summary"></app-summary-table>
      </div>
    </div>
  </div>
</div>

