import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';
import { Observable, of } from 'rxjs';
import { AuthResponse, CheckAuthResponse, Credentials } from './models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private gateway: ApiGatewayService = inject(ApiGatewayService);

  constructor() { }

  isLoggedIn(): Observable<CheckAuthResponse> {
    return this.gateway.get<CheckAuthResponse>('/checkauth');
  }

  authenticate(username: string, password: string): Observable<AuthResponse> {
    let credentials: Credentials = {
      username: username,
      password_text: password
    };

    return this.gateway.post<Credentials, AuthResponse>('/auth', credentials);
  }

  logout(): Observable<AuthResponse> {
    return this.gateway.post<any, AuthResponse>('/logout', undefined);
  }
}
