<div id="standard-layout" class="wrapper" *ngIf="loadApplication">
  <div class="rows">
    <app-side-menu />
    <div class="inner-col">
      <mat-toolbar>
          <h1>{{ title }}</h1>
          <span class="toolbar-spacer"></span>
          <app-top-nav [profile]="userprofile"></app-top-nav>
      </mat-toolbar>
     <div id="app-content">
        <div id="app-content-inner" [ngClass]="viewTitle">
          <router-outlet />
        </div>
      </div>
    </div>
  </div>
</div>
