import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Decimal } from 'decimal.js'
import { IQuoteItem, IQuoteItemFormGroup } from "./interfaces";


export enum SepratorStyle {
  SOLID=1,
  DOUBLELINE,
  DASHED,
  DOTTED,
}

export enum ItemType {
  PART='part',
  FINISHEDGOOD='finished_good',
  COMPOSITE='composite'
}

export class Quote {
  quoteItems: QuoteItem[] = [];
  private quoteCurrentLineNumber: number = 1;

  setLineNumber(lineNumber: number = 1) {
    this.quoteCurrentLineNumber = lineNumber;
  }

  public addQuoteItem(item: QuoteItem, context: QuoteItem | undefined = undefined) {
    item.quote_item_line_number = this.quoteCurrentLineNumber++;
    if (context) {
      context.parts?.push(item);
    } else {
      this.quoteItems.push(item);
    }
  }

  public getQuoteItemAtLine(linenumber: number): IQuoteItem|undefined {
    if (this.quoteItems.length) {
      for (let item of this.quoteItems) {
        if (item.quote_item_line_number == linenumber) {
          return item;
        }

        if (item.parts.length) {
          for (let subpart of item.parts) {
            if (subpart.quote_item_line_number == linenumber) {
              return subpart;
            }
          }
        }
      }
    }

    return undefined;
  }

  get grandTotal(): Decimal {
    let total = new Decimal(0);
    for (let item of this.quoteItems) {
      total = total.add(item.total);
    }

    return total;
  }

  get grandTotalIncMarkup(): Decimal {
    let total = new Decimal(0);

    for (let item of this.quoteItems) {
      total = total.add(item.totalWithMarkup);
    }

    return total;
  }
}

export abstract class QuoteItem implements IQuoteItem {
  protected type: ItemType = ItemType.PART;
  private static internal_id = 0;
  description: string = '';
  parts: IQuoteItem[] = [];
  quote_item_line_number: number = 0;
  quantity: number = 0;
  quote_item_part_id: string;
  quote_item_id: string = '';
  item_line_number: number = 0;
  quote_item_description: string = '';
  unit_price: number = 0;

  constructor(part: Partial<IQuoteItem>) {
    this.quote_item_part_id = part.quote_item_part_id ||
      (QuoteItem.internal_id++).toString();

    this.description = part.description || '';
    this.quote_item_description = part.quote_item_description || '';
    this.quote_item_id = part.quote_item_id || '';
  }

  abstract get formGroup(): any;

  get total(): Decimal {
    return new Decimal(0);
  }

  get totalWithMarkup(): Decimal {
    return new Decimal(0);
  }

  get itemType(): ItemType {
    return this.type;
  }
}

export class PartQuoteItem extends QuoteItem {
  protected override type: ItemType = ItemType.PART;
  price: number = 0;
  markup: number = 0;
  item_part_id: string;

  get part_id(): string {
    return this.item_part_id;
  }

  constructor(part: Partial<IQuoteItem>) {
    super(part);
    this.quantity = part.quantity || 0;
    this.price = part.unit_price || part.price || 0;
    this.unit_price = part.unit_price || 0;
    this.markup = part.markup || 0;
    this.item_part_id = part.item_part_id || part.quote_item_part_id || '';
  }

  get formGroup() {
    return new FormGroup<IQuoteItemFormGroup>({
      'quote_item_id': new FormControl<string>(this.quote_item_id || '', {nonNullable: true}),
      'item_part_id': new FormControl<string>(this.part_id || '', {nonNullable: true}),
      'item_part_type': new FormControl<ItemType>(this.type || ItemType.PART, {nonNullable: true}),
      'quote_item_description': new FormControl<string>(this.description || this.quote_item_description || '', {nonNullable: true}),
      'quantity': new FormControl<number>(this.quantity || 0, {nonNullable: true}),
      'unit_price': new FormControl<number>(this.price || this.unit_price || 0, {nonNullable: true}),
      'markup': new FormControl<number>(this.markup || 0, {nonNullable: true}),
      'item_line_number': new FormControl<number>(this.quote_item_line_number || 1, {nonNullable: true})
    });
  }

  override get total(): Decimal {
    let total = new Decimal(0);

    if (this.quantity && this.price) {
      let qty = new Decimal(this.quantity);
      let price = new Decimal(this.price);
      return qty.mul(price);
    }

    return total;
  }

  override get totalWithMarkup(): Decimal {
    let total = new Decimal(0);

    if (this.quantity && this.price && this.markup) {
      let qty = new Decimal(this.quantity);
      let price = new Decimal(this.price);
      let markup = new Decimal(this.markup);

      total = qty.mul(price)
      total = total.mul(markup);
    }

    return total;
  }
}

export class CompositeQuoteItem extends QuoteItem {
  protected override type = ItemType.COMPOSITE;
  override parts: PartQuoteItem[] = [];
  item_composite_id: string = '';

  constructor(part: Partial<IQuoteItem>) {
    super(part);
    this.item_composite_id = part.item_composite_id || part.quote_item_part_id || '';
    this.parts = [];
  }

  get composite_id() {
    return this.item_composite_id;
  }

  get formGroup(): FormGroup<IQuoteItemFormGroup> {
    return new FormGroup<IQuoteItemFormGroup>({
      'quote_item_id': new FormControl<string>(this.quote_item_id || '', {nonNullable: true}),
      'item_composite_id': new FormControl<string>(this.composite_id || '', {nonNullable: true}),
      'item_part_type': new FormControl<ItemType>(this.type || ItemType.COMPOSITE, {nonNullable: true}),
      'quote_item_description': new FormControl<string>(this.description || this.quote_item_description || '', {nonNullable: true}),
      'quantity': new FormControl<number>(this.quantity || 1, {nonNullable: true}),
      'parts': new FormArray<FormGroup<IQuoteItemFormGroup>>([]),
      'item_line_number': new FormControl<number>(this.quote_item_line_number || 1, {nonNullable: true})
    });
  }

  override get total(): Decimal {
    let total = new Decimal(0);

    for (let part of this.parts) {
      total = total.add(part.total);
    }

    return total;
  }

  override get totalWithMarkup(): Decimal {
    let total = new Decimal(0);

    for (let part of this.parts) {
      total = total.add(part.totalWithMarkup);
    }

    return total;
  }
}

export class FinishedGoodQuoteItem extends QuoteItem {
  protected override type = ItemType.FINISHEDGOOD;

  constructor(part: Partial<IQuoteItem>) {
    super(part);
    this.parts = [];
  }

  get id(): string {
    return this.quote_item_part_id;
  }

  get formGroup(): FormGroup<IQuoteItemFormGroup> {
    return new FormGroup<IQuoteItemFormGroup>({
      'quote_item_id': new FormControl<string>(this.quote_item_id || '', {nonNullable: true}),
      'item_part_id': new FormControl<string>(this.id || '', {nonNullable: true}),
      'item_part_type': new FormControl<ItemType>(this.type || ItemType.FINISHEDGOOD, {nonNullable: true}),
      'quote_item_description': new FormControl<string>(this.description || this.quote_item_description || '', {nonNullable: true}),
      'quantity': new FormControl<number>(this.quantity || 1, {nonNullable: true}),
      'parts': new FormArray<FormGroup<IQuoteItemFormGroup>>([]),
      'item_line_number': new FormControl<number>(this.quote_item_line_number || 1, {nonNullable: true})
    });
  }

  override get total(): Decimal {
    let total: Decimal = new Decimal(0);
    if (this.parts) {
      for (let part of this.parts) {
        total = total.add(part.total);
      }
    }

    return total;
  }

  override get totalWithMarkup(): Decimal {
    let total = new Decimal(0);

    if (this.parts) {
      for (let part of this.parts) {
        total = total.add(part.totalWithMarkup);
      }
    }

    return total;
  }
}

export class TextQuoteItem extends QuoteItem {
  get formGroup() {
    return new FormGroup({});
  }
}

export class SeparatorQuoteItem extends QuoteItem {
  private _style: SepratorStyle = SepratorStyle.SOLID;

  get style(): SepratorStyle {
    return this._style;
  }

  set style(newStyle: SepratorStyle) {
    this._style = newStyle
  }

  get formGroup() {
    return new FormGroup({});
  }
}

