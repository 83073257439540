import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { PartService } from '../../parts/shared/part.service';
import { IManufacturer } from '../../parts/shared/models';

@Component({
  selector: 'app-select-manufacturer-control',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectManufacturerControlComponent
    }
  ],
  templateUrl: './select-manufacturer-control.component.html',
  styleUrl: './select-manufacturer-control.component.scss'
})
export class SelectManufacturerControlComponent implements ControlValueAccessor, OnInit {
  protected service: PartService = inject(PartService);
  private disabled: boolean = false;
  @Input() label: string = '';

  @ViewChild('manufacturerSelect', {static: true}) manufacturerSelect!: MatSelect;

  manufacturers!: IManufacturer[];

  constructor() {}

  ngOnInit() {
    this.service.getManufacturers().subscribe((manufacturers) => {
      this.manufacturers = manufacturers;
    });

    this.manufacturerSelect.selectionChange.subscribe(val => {
      this.onChange(val.value);
    });
  }

  onChange: (newvalue: string) => void = (value) => {};
  onTouched: () => void = () => {};

  writeValue(obj: any): void {
      this.manufacturerSelect.writeValue(obj);
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }
}
