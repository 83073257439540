<div class="app-section-header">
  <div class="manager-panel app-section-header-item">
    <a mat-fab extended routerLink="/users/add">
      <mat-icon>add</mat-icon>
      Add User
    </a>
    <a mat-fab extended routerLink="/users/groups/add">
      <mat-icon>add</mat-icon>
      Add Group
    </a>
    <ng-content></ng-content>
  </div>
  <div class="app-section-header-item app-category-container">
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/users/list">Users</a>
    </div>
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/users/groups">Groups</a>
    </div>
  </div>
</div>
