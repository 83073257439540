import { CommonModule, NgClass } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PartHeaderComponent } from '../part-header/part-header.component';
import { StreamingService } from '../../core/streaming.service';

@Component({
  selector: 'app-part-list',
  standalone: true,
  imports: [
    CommonModule,
    NgClass,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    PartHeaderComponent
  ],
  templateUrl: './part-list.component.html',
  styleUrl: './part-list.component.scss'
})
export class PartListComponent {
  service: StreamingService = inject(StreamingService);

  parts: any[] = [];
  dataSource  = signal<any[]>([]);
  partGroups = signal<string[]>([]);
  partManufacturers = signal<string[]>([]);
  missingBuffer: string = '';
  pageSize: number = 15;
  curPage: number = 0;
  totalItem = signal<number>(0);

  ngOnInit() {
    this.getParts();
  }

  get isFirstPage(): boolean {
    return this.curPage == 0;
  }

  get isLastPage(): boolean {
    return this.curPage == this.totalPages;
  }

  setPageSize(e: any) {
    let oldPagesize = this.pageSize;
    this.pageSize = +e.target.value;
    let activePage = Math.max(1, this.activePage-1);
    let newPage = Math.ceil((Math.min(activePage, this.totalPages)*oldPagesize)/this.pageSize)
    this.setPage(newPage);
  }

  prevPage() {
    let page = Math.max(0, --this.curPage);
    this.setPage(page+1);
  }

  nextPage() {
    let page = Math.min(this.totalPages, ++this.curPage);
    this.setPage(page+1);
  }

  delete(id: string) {
    console.log(id);
  }

  get showingMessage(): string {
    let from: number = this.curPage*this.pageSize + 1;
    let total: number = this.parts.length;
    let to: number = Math.min((this.curPage+1)*this.pageSize, total);

    return `Showing ${from} - ${to} of ${total}`;
  }

  get totalPages(): number {
    return Math.ceil(this.parts.length/this.pageSize);
  }

  get paginationPages(): number[] {
    let pages = []
    if (this.curPage < 5) {
      for (let i: number = 2; i < 7; i++) {
        pages.push(i);
      }
    } else if (this.curPage == this.totalPages-1) {
      for (let i: number = this.curPage-4; i < this.totalPages; i++) {
        pages.push(i);
      }
    } else if (this.curPage >= (this.totalPages-4)) {
      for (let i: number = this.totalPages-5; i < this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      for (let i: number = this.curPage-1; i < Math.min(this.curPage+4, this.totalPages); i++) {
        pages.push(i);
      }
    }

    return pages;
  }

  get activePage(): number {
    return this.curPage+1;
  }

  isActivePage(pageNum: number): boolean {
    return pageNum == this.activePage;
  }

  setPage(page:number): void {
    this.curPage = Math.min(page-1, this.totalPages);
    let from: number = this.curPage*this.pageSize;
    let total: number = this.parts.length;
    let to: number = Math.min((this.curPage+1)*this.pageSize, total);
    this.dataSource.set(this.parts.slice(from, to));
  }

  getParts() {
    const partsurl = this.service.makeRequest("stream");
    const req = new Request(partsurl);
    fetch(req, {
      credentials: "include"
    })
    .then((response) => {
      let that = this;
      const reader = response.body?.getReader();
      return new ReadableStream({
        start(controller) {
          return readChunk();
          function readChunk(): any {
            return reader?.read().then(({done, value}) => {
              if (done) {
                controller.close();

                if (that.parts.length >= that.pageSize) {
                  that.dataSource.set(that.parts.slice(0, that.pageSize));
                } else {
                  that.dataSource.set(that.parts);
                }

                that.parts.map((x) => {
                  if (that.partGroups().indexOf(that.capitalise(x.part_group_ref)) < 0) {
                    that.partGroups().push(that.capitalise(x.part_group_ref));
                  }

                  if (that.partManufacturers().indexOf(that.capitalise(x.part_manufacturer)) < 0) {
                    that.partManufacturers().push(that.capitalise(x.part_manufacturer));
                  }
                });
                return;
              }

              const decoder = new TextDecoder();
              let resp = decoder.decode(value);
              let partsChunk = that.appendParts(resp);

              if (partsChunk.length) {
                that.parts = that.parts.concat(partsChunk);
                if (that.parts.length >= that.pageSize) {
                  that.dataSource.set(that.parts.slice(0, that.pageSize));
                } else {
                  that.dataSource.set(that.parts);
                }
              }

              return readChunk();
            });
          }
        }
      })
    });
  }

  appendParts(parts: any) {
    let container: any[] = [];
    let split_parts = parts.split("\r\n");

    if (split_parts.length > 0) {
      let num_chunks = split_parts.length;

      for (let i = 0; i < num_chunks; i++) {
        if (split_parts[i] == "") continue;

        if (split_parts[i].at(-1) != "]") {
          this.missingBuffer += split_parts[i];
          continue;
        }

        if (split_parts[i].charAt(0) != "[") {
          if (this.missingBuffer != "") {
            if (split_parts[i].at(-1) == "]") {
              this.missingBuffer += split_parts[i];
              container = container.concat(JSON.parse(this.missingBuffer));
              this.missingBuffer = "";
              continue;
            }
          }

          this.missingBuffer += split_parts[i];
          continue;
        }

        if (split_parts[i] != "") {
          container = container.concat(JSON.parse(split_parts[i]));
        }
      }
    }

    return container;
  }

  capitalise(name: string) {
    if (name == "") {
      return "None";
    }
    return name[0].toUpperCase() + name.slice(1)
  }
}
