<div id="cusomter-form" class="data-form">
  <div class="alert alert-success" *ngIf="showMessage">
    {{ successMessage }}

    <button mat-icon-button (click)="hideMessage()"><mat-icon>close</mat-icon></button>
  </div>

  <form [formGroup]="userFormGroup" (submit)="editUser()" *ngIf="userFormGroup">
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput formControlName="username" />
    </mat-form-field>
    <div
        *ngIf="username.invalid && (username.dirty || username.touched)"
        class="alert alert-danger">
      Username is required
    </div>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" type="password"/>
    </mat-form-field>
    <div
        *ngIf="password.invalid && (password.dirty || password.touched)"
        class="alert alert-danger">
      Password is required
    </div>

    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input matInput formControlName="first_name" />
    </mat-form-field>
    <div
        *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)"
        class="alert alert-danger">
      First name is required
    </div>

    <mat-form-field>
      <mat-label>Surname</mat-label>
      <input matInput formControlName="surname" />
    </mat-form-field>
    <div
        *ngIf="surname.invalid && (surname.dirty || surname.touched)"
        class="alert alert-danger">
      Surname is required
    </div>

    <div style="position: relative;">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" (keyup)="checkEmail($event)"/>
      </mat-form-field>
      <div style="position: absolute; right: 0.25em; top: 0; text-align: center; display: flex; align-items: center;"
        *ngIf="checkingEmail">
        <mat-spinner [diameter]="16"></mat-spinner>
        <span style="font-size: 0.8em; display: inline-block; padding-left: 0.5em;">Checking ...</span>
      </div>
    </div>
    <div
        *ngIf="email.invalid && (email.dirty || email.touched)"
        class="alert alert-danger">
      Email is required
    </div>
    <div
        *ngIf="email.hasError('emailNotAvailable')"
        class="alert alert-danger">
      {{ email.getError('emailNotAvailable') }}
    </div>

    <app-select-user-group formControlName="group"></app-select-user-group>
    <div
        *ngIf="group.invalid && (group.dirty || group.touched)"
        class="alert alert-danger">
      User group is required
    </div>

    <div class="form-action form-action-right">
      <button mat-flat-button>{{ buttonText }}</button>
    </div>
  </form>
</div>
