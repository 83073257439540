import { Component, ComponentRef, effect, ElementRef, inject, OnDestroy, OnInit, Renderer2, signal, Signal, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ConfiguratorComponent } from '../../configurator/configurator.component';
import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { QuoteService } from '../../shared/quote.service';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CdkDropList, DragDropModule, CdkDragDrop, DragRef, DragDrop, DropListRef } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { Decimal } from "decimal.js";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { SelectcustomerComponent } from '../../../controls/selectcustomer/selectcustomer.component';
import { ItemType } from '../shared/models';
import { ActivatedRoute } from '@angular/router';
import { IQuoteItemFormGroup, IQuoteItem, IQuoteFormGroup } from '../shared/interfaces';
import { Observable, Subscription } from 'rxjs';

export const TULL_DATE_FORMAT = {
    parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-quote-edit',
  standalone: true,
  imports: [
    CommonModule,
    ConfiguratorComponent,
    ReactiveFormsModule,
    MatButtonModule,
    MatDatepickerModule,
    DragDropModule,
    CdkDropList,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    SelectcustomerComponent
  ],
  providers: [
    provideMomentDateAdapter(TULL_DATE_FORMAT, {useUtc: true}),
  ],
  templateUrl: './quote-edit.component.html',
  styleUrl: './quote-edit.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class QuoteEditComponent implements OnInit, OnDestroy {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private quoteService: QuoteService = inject(QuoteService);
  private insertionPoint: number = 0;
  private insertionArrayIndex: number | undefined = undefined;

  quoteFormGroup!: FormGroup;
  grandTotal: Decimal = new Decimal(0);
  grandTotalIncMarkup: Decimal = new Decimal(0);
  private clipboard!: Partial<IQuoteItem>;
  inFinishedGoodContext: boolean = false;
  private activeFinishedGood: string = '';
  private finishedGoodNameSubscription!: Subscription;
  activateFinishedGoodMode: boolean = false;
  activateCompositeMode: boolean = false;
  resetConfigurator = signal(false);

  inCompositeContext: boolean = false;
  private activeComposite: string = '';

  buttonText: string = 'Add';
  editMode: boolean = false;
  activeFinishedGoodDescription: string = '';

  @ViewChild('quoteItemContainer', {read: ViewContainerRef}) quoteItemContainer!: ViewContainerRef;
  @ViewChild('dragBoundary') dragBoundary!: ElementRef;

  get quoteItems(): FormArray<FormGroup<IQuoteItemFormGroup>> {
    return this.quoteFormGroup.controls['quote_items'] as FormArray<FormGroup<IQuoteItemFormGroup>>;
  }

  canCopy(): boolean {
    return this.inFinishedGoodContext;
  }

  updateInsertion: any = undefined;
  setInsertionPoint(item: Partial<IQuoteItem>) {
    if (item.item_line_number) {
      this.destroyContext();
      if (item.item_line_number == this.insertionPoint) {
        this.insertionPoint = 0;
        this.resetConfigurator.set(true);
        return;
      }

      if (this.insertionPoint > 0) {
        this.resetConfigurator.set(true);
      }

      if (this.resetConfigurator()) {
        this.updateInsertion = function() {
          if (item.item_line_number) {
            this.insertionPoint = +item.item_line_number;
            this.editPart(item.item_part_id, this.quoteItems);
          }
        }
      } else {
        this.insertionPoint = +item.item_line_number;
        this.editPart(item.item_part_id, this.quoteItems);
      }
    }
  }

  isInsertionPointActive(line_number: number | undefined): boolean {
    if (line_number && +line_number == this.insertionPoint) {
      return true;
    }
    return false;
  }

  canPaste(item: any): boolean {
    return this.inFinishedGoodContext &&
      !this.inCompositeContext &&
      this.clipboard != undefined &&
      this.clipboard.item_part_id != item.item_part_id &&
      item.quote_item_description == '' &&
      !item.parts.length
  }

  getcontrols(items: FormArray<FormGroup<IQuoteItemFormGroup>>|undefined) {
    if (items) {
      return items.controls;
    }

    return [];
  }

  constructor() {
    effect(() => {
      if (!this.resetConfigurator()) {
        if (this.updateInsertion) {
          this.updateInsertion();
        }
        this.updateInsertion = undefined;
      }
    });
  }

  ngOnInit(): void {
    Decimal.set({precision: 20});
    let quote_id = this.route.snapshot.params['id'] || undefined;

    if (quote_id) {
      this.quoteService.getQuote(quote_id).subscribe(quote => {
        this.quoteFormGroup = this.quoteService.makeQuoteFormGroup(quote);
        this.updateTotal();
        this.updateTotalsMonitor();
        this.setEditMode();
        this.quoteFormGroup.controls['dtm_quote'].valueChanges.subscribe(newVal => {
          console.log(newVal);
        });
      });
    } else {
      this.quoteFormGroup = this.quoteService.makeQuoteFormGroup();
      this.updateTotalsMonitor();
    }

    this.quoteService.activeFinishedGood.subscribe((item) => {
      this.activeFinishedGood = item.controls.quote_item_id.value;
      if (item.controls.quote_item_description.value) {
        this.activeFinishedGoodDescription = item.controls.quote_item_description.value;
      }
      this.finishedGoodNameSubscription = item.controls
        .quote_item_description.valueChanges.subscribe((newVal: string) => {
          this.activeFinishedGoodDescription = newVal;
        });
    });
  }

  setEditMode() {
    this.editMode = true;
    this.buttonText = "Update";
  }

  updateTotalsMonitor() {
    this.quoteFormGroup.valueChanges.subscribe((newValue) => {
      this.updateTotal();
    });

  }

  updateTotal() {
      this.grandTotal = this.quoteService.grandTotal();
      this.grandTotalIncMarkup = this.quoteService.grandTotalIncMarkup();
  }

  quoteItemTotal(item: FormGroup<IQuoteItemFormGroup> | undefined): Decimal {
    if (item && item.value.quantity && item.value.unit_price) {
      return this.total([+item.value.quantity,
                         +item.value.unit_price])
    }

    return new Decimal(0);
  }

  quoteItemTotalIncMarkup(item: FormGroup<IQuoteItemFormGroup>): Decimal {
    if (item && item.value.quantity && item.value.unit_price && item.value.markup) {
      return this.total([+item.value.quantity,
                         +item.value.unit_price,
                         +item.value.markup])
    }

    return new Decimal(0);
  }

  quoteItemAggTotal(items: FormArray<FormGroup<IQuoteItemFormGroup>> | undefined,
                   total: Decimal = new Decimal(0)): Decimal {
    if (items) {
      for (let quoteItem of items.controls) {
        if (quoteItem.value.unit_price && quoteItem.value.quantity &&
            quoteItem.controls.item_part_type.value == 'part') {
          total = total.add(this.quoteItemTotal(quoteItem));
        }

        if (quoteItem.controls.parts) {
          total = this.quoteItemAggTotal(quoteItem.controls.parts, total);
        }
      }
    }
    return total;
  }

  quoteItemAggTotalIncMarkup(items: FormArray<FormGroup<IQuoteItemFormGroup>> | undefined,
                            total: Decimal = new Decimal(0)): Decimal {
    if (items) {
      for (let quoteItem of items.controls) {
        if (quoteItem.value.unit_price && quoteItem.value.quantity && quoteItem.value.markup) {
          total = total.add(this.total([+quoteItem.value.quantity,
                                        +quoteItem.value.unit_price,
                                        +quoteItem.value.markup]));
        }

        if (quoteItem.controls.parts) {
          total = total.add(this.quoteItemAggTotalIncMarkup(quoteItem.controls.parts, total));
        }
      }
    }

    return total;
  }

  copyItem(item: FormGroup<IQuoteItemFormGroup>) {
    this.clipboard = item.value as IQuoteItem;
  }

  pasteItem(duplicate: FormGroup<IQuoteItemFormGroup>) {
    if (duplicate.value.item_part_id == this.clipboard.item_part_id) {
      return;
    }

    duplicate.controls.quote_item_description.setValue(
      this.clipboard.quote_item_description || '');

    if (this.clipboard.parts && this.clipboard.parts.length) {
      this.cloneParts(this.clipboard.parts);
    }
  }

  cloneParts(partsFrom: Partial<IQuoteItem>[]) {
    for (let part of partsFrom) {
      if (part.item_part_type == ItemType.PART) {
        let new_part = {
          "description": part.quote_item_description,
          "id": part.item_part_id,
          "quantity": part.quantity,
          "part_price": part.unit_price,
          "markup": part.markup,
          "type": ItemType.PART
        }

        this.quoteService.makeQuoteItem(new_part);
      }

      if (part.item_part_type == ItemType.COMPOSITE) {
        let new_part = {
          "description": part.quote_item_description,
          "id": part.item_part_id,
          "type": ItemType.COMPOSITE
        }

        this.quoteService.makeQuoteItem(new_part);
        if (part.parts?.length) {
          this.cloneParts(part.parts);
        }

        this.destroyCompositeContext();
      }
    }
  }

  total(numbers: number[]): Decimal {
    let decimals = numbers.map(x => new Decimal(x))
    return decimals.reduce((a: Decimal, b: Decimal) => a.mul(b)).toDecimalPlaces(5);
  }

  handlePartSelect(part: any) {
    if (part.type == ItemType.COMPOSITE) {
      this.inCompositeContext = true;
    }
    this.quoteService.makeQuoteItem(part, this.insertionArrayIndex);
    let curLineNumber = this.onItemReorder(this.quoteItems);
    this.quoteService.setCurrentLineNumber(curLineNumber);
  }

  editPart(part_id: string | undefined,
           container: FormArray<FormGroup<IQuoteItemFormGroup>>,
           parents: FormGroup<IQuoteItemFormGroup>[] = [],
          found: boolean = false): boolean {
    for (let [idx, item] of container.controls.entries()) {
      if (item.controls.item_part_id?.value == part_id) {
        if (item.controls.item_part_type.value == ItemType.COMPOSITE ||
           item.controls.item_part_type.value == ItemType.FINISHEDGOOD) {
          this.insertionArrayIndex = 0;
          if (!parents.length) {
            if (item.controls.item_part_type.value == ItemType.FINISHEDGOOD) {
              this.editFinishedGood(item);
            }
          } else {
            parents.push(item);
          }
        } else {
          this.insertionArrayIndex = idx+1;
        }
        return true;
      }

      if (item.controls.parts?.length) {
        parents.push(item);
        found = this.editPart(part_id, item.controls.parts, parents, found);
        if (!found) {
          parents.pop();
        } else {
          if (parents.length) {
            let itemparent;
            while (itemparent = parents.shift() ) {
              if (itemparent.controls.item_part_type.value == ItemType.FINISHEDGOOD) {
                this.editFinishedGood(itemparent);
              }

              if (itemparent.controls.item_part_type.value == ItemType.COMPOSITE) {
                this.editComposite(itemparent);
              }
            }
          }
          return found;
        }
      }
    }

    return found;
  }

  editFinishedGood(item: FormGroup<IQuoteItemFormGroup>, editButton: boolean = false) {
    this.quoteService.editFinishedGood(item);
    this.inFinishedGoodContext = true;
    this.activateFinishedGoodMode = true;
    if (editButton) {
      this.insertionPoint = 0;
    }
  }

  editComposite(item: FormGroup<IQuoteItemFormGroup>, editButton: boolean = false) {
    this.quoteService.editComposite(item);
    this.inCompositeContext = true;
    this.activateCompositeMode = true;
    if (editButton) {
      this.insertionPoint = 0;
    }
  }

  addFinishedGood() {
    this.quoteService.makeQuoteItem({type: ItemType.FINISHEDGOOD});
    this.inFinishedGoodContext = true;
  }

  destroyContext() {
    this.quoteService.clearStacks();
    this.destroyCompositeContext();
    this.destroyFinishedGoodContext();
  }

  destroyFinishedGoodContext() {
    this.quoteService.doneFinishedGood();
    this.inFinishedGoodContext = false;
    this.activeFinishedGoodDescription = '';
    this.activateFinishedGoodMode = false;
    this.activateCompositeMode = false;
  }

  destroyCompositeContext() {
    this.quoteService.doneComposite();
    this.inCompositeContext = false;
    this.activateCompositeMode = false;
  }

  deleteItemAt(index: number, quoteItems: FormArray<FormGroup<IQuoteItemFormGroup>> | undefined) {
    if (quoteItems) {
      quoteItems.removeAt(index);
      let curLineNumber = this.onItemReorder(this.quoteItems);
      this.quoteService.setCurrentLineNumber(curLineNumber);
      this.updateTotal();
    }
  }

  drop(event: CdkDragDrop<any>, items: FormArray<FormGroup<IQuoteItemFormGroup>> | undefined) {
    if (items) {
      let item = items.at(event.previousIndex);
      items.removeAt(event.previousIndex);
      items.insert(event.currentIndex, item);
      this.onItemReorder(this.quoteItems);
    }
  }

  onItemReorder(quoteItems: FormArray<FormGroup<IQuoteItemFormGroup>>, curLineNumber: number = 1) {
    for (let item of quoteItems.controls) {
      item.controls.item_line_number.setValue(curLineNumber++);
      if (item.controls.parts?.length) {
        curLineNumber = this.onItemReorder(item.controls.parts, curLineNumber);
      }
    }
    return curLineNumber;
  }

  onSubmit() {
    if (this.quoteFormGroup.valid) {
      if (this.editMode) {
        this.quoteService.updateQuote(this.quoteFormGroup.value).subscribe(msg => {
          console.log(msg);
        });
      } else {
        this.quoteService.storeQuote(this.quoteFormGroup.value).subscribe(msg => {
          console.log(msg);
        });
      }
    }
  }

  ngOnDestroy(): void {
      this.quoteService.setCurrentLineNumber(1);
  }
}
