import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Address, AddressFormGroup } from '../../controls/shared/models';

export interface ICustomer {
  customer_id?: string;
  customer_name: string;
  address: Address[];
}

export interface ICustomerListView {
  customer_id: string;
  customer_name: string;
  address_id: string;
  address_line1: string;
  city: string;
  postal_code: string;
}

export interface CustomerFormGroup {
  customer_id: FormControl<string>;
  customer_name: FormControl<string>;
  address: FormArray<FormGroup<AddressFormGroup>>;
}

export class Customer implements ICustomer {
  customer_id: string;
  customer_name: string;
  address: Address[];

  constructor(customer: ICustomer | undefined = undefined) {
    this.customer_id = customer?.customer_id || '';
    this.customer_name = customer?.customer_name || '';
    this.address = [];

    if (customer?.address) {
      for (let address of customer.address) {
        this.address.push(new Address(address));
      }
    }

    if (!this.address.length) {
      this.address.push(new Address());
    }
  }

  get formGroup(): FormGroup<CustomerFormGroup> {
    let fg = new FormGroup<CustomerFormGroup>({
      "customer_id": new FormControl(this.customer_id || '', {nonNullable: true}),
      "customer_name": new FormControl(this.customer_name || '', {nonNullable: true, validators: [Validators.required]}),
      "address": new FormArray<FormGroup<AddressFormGroup>>([])
    });

    return fg;
  }
}
