import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IPartNoteFormGroup } from '../../shared/models';

@Component({
  selector: 'app-part-note-form-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './part-note-form-group.component.html',
  styleUrl: './part-note-form-group.component.scss'
})
export class PartNoteFormGroupComponent {
  @Input() fg!: FormGroup<IPartNoteFormGroup>;
}
