import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  gateway: ApiGatewayService = inject(ApiGatewayService);

  constructor() { }

  makeRequest(action: string) {
    return this.gateway.endpoint + '/stream/' + action;
  }
}
