import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from '../../core/api-gateway.service';
import { Customer, ICustomerListView } from './models';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  gateway: ApiGatewayService = inject(ApiGatewayService);

  constructor() { }

  createCustomer(customer: Customer) {
    return this.gateway.post<Customer, any>('/customers', customer);
  }

  updateCustomer(customer: Customer) {
    return this.gateway.post<Customer, any>('/customers/edit', customer);
  }

  listviewcustomers() {
    return this.gateway.get<ICustomerListView[]>('/customers');
  }

  getcustomer(customer_id: string) {
    return this.gateway.get<Customer>('/customers/' + customer_id);
  }
}
