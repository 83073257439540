import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { CustomerService } from '../../customers/shared/customer.service';
import { ICustomerListView } from '../../customers/shared/models';

@Component({
  selector: 'app-selectcustomer',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectcustomerComponent
    }
  ],
  templateUrl: './selectcustomer.component.html',
  styleUrl: './selectcustomer.component.scss'
})
export class SelectcustomerComponent implements ControlValueAccessor, OnInit {
  protected service: CustomerService = inject(CustomerService);
  private disabled: boolean = false;
  @Input() label: string = "";

  @ViewChild('customer', {static: true}) customer!: MatSelect;

  customers!: ICustomerListView[];

  constructor() {}

  ngOnInit() {
    this.service.listviewcustomers().subscribe((customers) => {
      this.customers = customers;
    });

    this.customer.selectionChange.subscribe(val => {
      this.onChange(val.value);
    });
  }

  onChange: (newvalue: string) => void = (value) => {};
  onTouched: () => void = () => {};

  writeValue(obj: any): void {
      this.customer.writeValue(obj);
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }
}
