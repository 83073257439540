import { Component } from '@angular/core';
import { UserHeaderComponent } from '../../user-header/user-header.component';

@Component({
  selector: 'app-usergroup-edit',
  standalone: true,
  imports: [
    UserHeaderComponent
  ],
  templateUrl: './usergroup-edit.component.html',
  styleUrl: './usergroup-edit.component.scss'
})
export class UsergroupEditComponent {

}
