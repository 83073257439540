import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { StandardLayoutComponent } from './layouts/standard-layout/standard-layout.component';
import { QuotesComponent } from './plm/quotes/quotes.component';
import { PartsComponent } from './parts/parts.component';
import { MaintenanceComponent } from './plm/maintenance/maintenance.component';
import { CustomersComponent } from './customers/customers.component';
import { UsersComponent } from './users/users.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { CustomerListComponent } from './customers/customer-list/customer-list.component';
import { CustomerEditComponent } from './customers/customer-edit/customer-edit.component';
import { authGuard } from './core/auth.guard';
import { QuoteListComponent } from './plm/quotes/quote-list/quote-list.component';
import { QuoteEditComponent } from './plm/quotes/quote-edit/quote-edit.component';
import { PartListComponent } from './parts/part-list/part-list.component';
import { PartEditComponent } from './parts/part-edit/part-edit.component';
import { ManufacturerComponent } from './manufacturer/manufacturer.component';
import { SupplierComponent } from './supplier/supplier.component';
import { PartGroupComponent } from './parts/part-group/part-group.component';
import { CompositeEditComponent } from './parts/composite-edit/composite-edit.component';
import { UsergroupListComponent } from './users/groups/usergroup-list/usergroup-list.component';
import { UserGroupsComponent } from './users/groups/user-groups.component';
import { UsergroupEditComponent } from './users/groups/usergroup-edit/usergroup-edit.component';
import { PartConfigurationComponent } from './parts/part-configuration/part-configuration.component';
import { PartConfigurationListComponent } from './parts/part-configuration/part-configuration-list/part-configuration-list.component';
import { PartConfigurationEditComponent } from './parts/part-configuration/part-configuration-edit/part-configuration-edit.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: StandardLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'quotes',
        component: QuotesComponent,
        title: 'Quotes',
        children: [
          {
            path: 'list',
            component: QuoteListComponent,
            title: 'Quotes'
          },
          {
            path: 'add',
            component: QuoteEditComponent,
            title: 'Quotes'
          },
          {
            path: ':id',
            component: QuoteEditComponent,
            title: 'Quotes'
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'parts',
        component: PartsComponent,
        title: 'Parts',
        children: [
          {
            path: 'list',
            component: PartListComponent,
            title: 'Parts'
          },
          {
            path: 'add',
            component: PartEditComponent,
            title: 'Add Part'
          },
          {
            path: 'manufacturer',
            component: ManufacturerComponent,
            title: 'Part Manufacturers'
          },
          {
            path: 'supplier',
            component: SupplierComponent,
            title: 'Part Suppliers'
          },
          {
            path: 'groups',
            component: PartGroupComponent,
            title: 'Part Groups'
          },
          {
            path: 'composites',
            component: PartsComponent,
            children: [
              {
                path: 'add',
                component: CompositeEditComponent,
                title: 'Add Composite'
              },
              {
                path: ':id',
                component: CompositeEditComponent,
                title: 'Edit Composite'
              }

            ]
          },
          {
            path: 'configurations',
            component: PartConfigurationComponent,
            pathMatch: 'full',
            children: [
              {
                path: '',
                component: PartConfigurationListComponent,
                'title': 'Part Configurations'
              },
              {
                path: 'add',
                component: PartConfigurationEditComponent,
                'title': 'Create Configuration'
              },
              {
                path: ':id',
                component: PartConfigurationEditComponent,
                title: 'Edit Configuration'
              }
            ]
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
          {
            path: ':id',
            component: PartEditComponent,
            title: 'Edit Part'
          },
        ]
      },
      {
        path: 'maintenance',
        component: MaintenanceComponent,
        title: 'Maintenance'
      },
      {
        path: 'customers',
        component: CustomersComponent,
        children: [
          {
            path: 'list',
            component: CustomerListComponent,
            title: 'Customers'
          },
          {
            path: 'add',
            component: CustomerEditComponent,
            title: 'Add Customer'
          },
          {
            path: ':id',
            component: CustomerEditComponent,
            title: 'Edit Customer'
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full',
          }
        ]
      },
      {
        path: 'users',
        component: UsersComponent,
        children: [
          {
            path: 'groups',
            component: UserGroupsComponent,
            children: [
              {
                path: '',
                component: UsergroupListComponent,
                pathMatch: 'full',
                title: 'User Groups'
              },
              {
                path: 'add',
                component: UsergroupEditComponent,
                title: 'Add Group',
                pathMatch: 'full'
              },
              {
                path: ':id',
                component: UsergroupEditComponent,
                pathMatch: 'full',
                title: 'Edit Group'
              }
            ]
          },
          {
            path: 'list',
            component: UserListComponent,
            title: 'Users'
          },
          {
            path: 'add',
            component: UserEditComponent,
            title: 'Add User'
          },
          {
            path: ':id',
            component: UserEditComponent,
            title: 'Edit User'
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: '',
        redirectTo: 'quotes',
        pathMatch: 'full'
      }
    ]
  }
];
