import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { QuoteService } from '../../shared/quote.service';
import { IQuoteListView } from '../shared/interfaces';
import { CommonModule } from '@angular/common';
import { SummaryChartComponent } from '../../../views/summary-chart/summary-chart.component';
import { SummaryTableComponent } from '../../../views/summary-table/summary-table.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-quote-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    SummaryChartComponent,
    SummaryTableComponent,
    MatTooltipModule
  ],
  templateUrl: './quote-list.component.html',
  styleUrl: './quote-list.component.scss'
})
export class QuoteListComponent implements OnInit {
  private service: QuoteService = inject(QuoteService);
  quotes!: IQuoteListView[];
  summaries_to_show: string[] = [];

  ngOnInit(): void {
      this.service.loadquotes().subscribe(quotes => {
        this.quotes = quotes;
      });
  }

  toggleHotJob(quote: IQuoteListView) {
    quote.hot_job = !quote.hot_job;
  }

  toggleSummary(quote_id: string) {
    if (this.summaries_to_show.includes(quote_id)) {
      this.summaries_to_show = this.summaries_to_show.filter(idtocheck => {
        return idtocheck != quote_id;
      });
    } else {
      this.summaries_to_show.push(quote_id);
    }
  }

  showSummary(quote_id: string) {
    return this.summaries_to_show.includes(quote_id);
  }

}
