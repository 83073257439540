<nav [ngClass]="['nav', getnavclass()]">
  <mat-toolbar color="primary"><mat-icon (click)="togglemenu()">menu</mat-icon><h4 *ngIf="!closed">Tull</h4></mat-toolbar>
    <!--<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="100"><path d="M12.513,3.5,12.5,2H11A11,11,0,0,0,8.9,23.8a11.1,11.1,0,0,0,2.078.2A11.041,11.041,0,0,0,22,13V11.5H12.5Zm6.343,11A7.985,7.985,0,1,1,9.511,5.143L9.5,14.5Z"/><path d="M14.5.005V9.5H24A9.542,9.542,0,0,0,14.5.005Z"/></svg>
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="100"><path d="M3.5,21H24v3H3.5c-1.93,0-3.5-1.57-3.5-3.5V0H3V20.5c0,.276,.224,.5,.5,.5ZM16,4v3h2.879l-4.379,4.379-4-4-5.561,5.561,2.121,2.121,3.439-3.439,4,4,6.5-6.5v2.879h3V4h-8Z"/></svg>-->

  <ul [ngClass]="['nav__list']">
      <li class="nav__listitem" routerLinkActive="nav__listitem-active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512">
                  <path d="m176.3,331.6c18.4,0 36-6 50.7-17.2 2.4-1.8 7.2-8.7 1-15.6l-51-51.2v-72.3c-0.8-9.8-8.8-10.7-11.8-10.3-41.4,5.5-72.6,41.1-72.6,82.9 0,46.1 37.6,83.7 83.7,83.7zm-20.2-143.2v59.4c0,5.4 2.1,10.5 5.9,14.3l42,42c-8.6,4.3-18,6.5-27.8,6.5-34.7,0-62.8-28.2-62.8-62.8 0.1-27.1 17.7-50.9 42.7-59.4z"/>
                  <path d="m241.8,286.9c7.1,6.2 13.8,1.3 15.6-1 11.3-14.7 17.2-32.3 17.2-50.7 0-41.7-31.2-77.4-72.6-82.9-4.5-0.6-11.4,2-11.8,10.3v68.5c0,2.8 1.1,5.4 3.1,7.4l48.5,48.4zm-30.6-111.2c25,8.5 42.7,32.2 42.7,59.4 0,9.8-2.2,19.2-6.5,27.8l-36.1-36.1v-51.1z"/>
                  <path d="m436.8,11h-361.6c-27,0-48.9,22-48.9,48.9 0,18.9 10.8,35.4 26.6,43.5v281.9c0,5.8 4.7,10.4 10.4,10.4h182.4v37l-50.5,50.5c-4.1,4.1-4.1,10.7 0,14.7 4.1,4.1 10.7,4.1 14.7,0l46.2-46.2 46.2,46.2c6.7,5.8 12.7,2 14.7,0 4.1-4.1 4.1-10.7 0-14.7l-50.5-50.5v-37h182.4c5.8,0 10.4-4.7 10.4-10.4v-281.9c15.7-8.1 26.5-24.6 26.5-43.5 5.68434e-14-26.9-22-48.9-49-48.9zm1.6,363.9h-364.8v-266h364.8v266zm-1.6-286.9h-361.6c-15.5,0-28.1-12.6-28.1-28.1 0-15.5 12.6-28.1 28.1-28.1h361.7c15.5,0 28.1,12.6 28.1,28.1-0.1,15.5-12.7,28.1-28.2,28.1z"/>
                  <path d="m409,327.5c5.8,0 10.4-4.7 10.4-10.4v-150.4c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v150.4c-0.1,5.8 4.6,10.4 10.4,10.4z"/>
                  <path d="m363.6,327.5c5.8,0 10.4-4.7 10.4-10.4v-114.4c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v114.4c-5.68434e-14,5.8 4.7,10.4 10.4,10.4z"/>
                  <path d="m318.3,327.5c5.8,0 10.4-4.7 10.4-10.4v-87c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v87c-5.68434e-14,5.8 4.7,10.4 10.4,10.4z"/>
              </svg>
              <p>Dashboard</p>
          </a>
      </li>
      <li class="nav__listitem" routerLinkActive="nav__listitem-active">
          <a routerLink="/quotes">
              <mat-icon style="width: 32px; height: 32px; font-size: 32px">analytics</mat-icon>
              <p>Quotes</p>
          </a>
      </li>
      <li class="nav__listitem" routerLinkActive="nav__listitem-active" *ngIf="rootmode || adminmode">
          <a routerLink="/parts">
              <mat-icon style="width: 32px; height: 32px; font-size: 32px">construction</mat-icon>
              <p>Parts</p>
          </a>
      </li>
      <li class="nav__listitem" routerLinkActive="nav__listitem-active" *ngIf="rootmode || adminmode">
          <a routerLink="/maintenance">
              <mat-icon style="width: 32px; height: 32px; font-size: 32px">engineering</mat-icon>
              <p>Maintenance</p>
          </a>
      </li>
      <li class="nav__listitem" routerLinkActive="nav__listitem-active" *ngIf="rootmode || adminmode">
          <a routerLink="/customers">
              <mat-icon style="width: 32px; height: 32px; font-size: 32px">location_city</mat-icon>
              <p>Customers</p>
          </a>
      </li>
      <li class="nav__listitem" routerLinkActive="nav__listitem-active" *ngIf="rootmode || adminmode">
          <a routerLink="/users">
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 24 24" width="100" data-name="Layer 1"><path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm6.5 11h-13a1 1 0 0 1 -1-1v-.5a7.5 7.5 0 0 1 15 0v.5a1 1 0 0 1 -1 1zm3.5-15a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm-1.421 2.021a6.825 6.825 0 0 0 -4.67 2.831 9.537 9.537 0 0 1 4.914 5.148h6.677a1 1 0 0 0 1-1v-.038a7.008 7.008 0 0 0 -7.921-6.941z"/></svg>
              <p>Users</p>
          </a>
      </li>
  </ul>
</nav>
