import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { User, UserFormGroup } from '../shared/models';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../core/user.service';
import { MatIconModule } from '@angular/material/icon';
import { SelectUserGroupComponent } from '../../controls/select-user-group/select-user-group.component';
import { debounceTime, distinctUntilChanged, of, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'app-user-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    SelectUserGroupComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './user-edit.component.html',
  styleUrl: './user-edit.component.scss'
})
export class UserEditComponent implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private service: UserService = inject(UserService);
  private emailText$ = new Subject<string>();
  checkingEmail: boolean = false;

  user!: User;
  userFormGroup!: FormGroup<UserFormGroup>;
  successMessage = 'User successfully created.';
  buttonText: string = 'Add';
  showMessage: boolean = false;
  editMode: boolean = false;

  get username(): FormControl {
    return this.userFormGroup.controls.username;
  }

  get password(): FormControl {
    return this.userFormGroup.controls.password;
  }

  get email(): FormControl {
    return this.userFormGroup.controls.email;
  }

  get first_name(): FormControl {
    return this.userFormGroup.controls.first_name;
  }

  get surname(): FormControl {
    return this.userFormGroup.controls.surname;
  }

  get group(): FormControl {
    return this.userFormGroup.controls.group;
  }

  constructor() {}

  ngOnInit(): void {
    let username = this.route.snapshot.params['id'] || undefined;
    if (username) {
      this.service.getUser(username).subscribe(user => {
        this.user = new User(user);
        this.userFormGroup = this.user.formGroup;
        this.userFormGroup.controls.password.removeValidators(Validators.required)
        this.setEditMode();
      });

      return;
    }

    this.user = new User();
    this.userFormGroup = this.user.formGroup;

    this.emailText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((emailText: string, index: number) => {
        this.checkingEmail = true;
        return this.service.checkEmail(emailText);
      })
    ).subscribe(resp => {
      if (resp.email_exists == 1) {
        this.email.setErrors({...(this.email.errors || {}),
                                 'emailNotAvailable': 'This email is already registered'});
      }
      this.checkingEmail = false;
    });
  }

  editUser() {
    if (this.userFormGroup.valid) {
      if (this.editMode) {
        this.service.updateUser(
          new User(this.userFormGroup.value as User)
        ).subscribe(msg => {
          this.successMessage = 'User successfully updated';
          this.showMessage = true;
        });
      } else {
        this.service.createUser(
          new User(this.userFormGroup.value as User)
        ).subscribe(msg => {
          this.showMessage = true;
          this.setEditMode();
        });

      }
    }
  }

  checkEmail(event: any) {
    if (this.email.valid) {
      this.emailText$.next(this.email.value);
    }
  }

  setEditMode() {
    this.editMode = true;
    this.buttonText = 'Update';
  }

  hideMessage() {
    this.showMessage = false;
  }

}
