<div [formGroup]="fg">
  <mat-form-field>
    <mat-label>Part Accessory Reference</mat-label>
    <input matInput type="text" formControlName="accessory_ref" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Accessory Description</mat-label>
    <textarea matInput formControlName="accessory_description"></textarea>
  </mat-form-field>
</div>
