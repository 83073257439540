import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import * as echarts from 'echarts';
import { IQuoteSummary } from '../../plm/quotes/shared/interfaces';

@Component({
  selector: 'app-summary-chart',
  standalone: true,
  imports: [],
  templateUrl: './summary-chart.component.html',
  styleUrl: './summary-chart.component.scss'
})
export class SummaryChartComponent implements OnInit {

  @ViewChild('chart', {static: true}) chart!: ElementRef;
  @Input() summary!: IQuoteSummary[];

  ngOnInit(): void {
    let summaryChart = echarts.init(this.chart.nativeElement);
    summaryChart.setOption({
      title: {
        text: 'Quote Summary'
      },
      tooltip: {},
      xAxis: {
        data: this.summary.flatMap(item => item.part_group_ref),
        axisLabel: {
          rotate: 45
        }
      },
      yAxis: {},
      series: [
        {
          name: 'Total',
          type: 'bar',
          data: this.summary.flatMap(item => item.total)
        },
        {
          name: 'Total Inc Markup',
          type: 'bar',
          data: this.summary.flatMap(item => item.totalincmarkup)
        }
      ]
    });
  }
}
