import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiConfig, API_CONFIG } from './api-conf';


@Injectable({
  providedIn: 'root'
})
export class ApiGatewayService {
  private _endpoint:string;
  private options: {};

  constructor(@Inject(API_CONFIG) config: ApiConfig, private http:HttpClient) {
    this._endpoint = config.url;
    this.options = config.options;
  }

  set endpoint(val:string) {
    this._endpoint = val;
  }

  get endpoint():string {
    return this._endpoint;
  }

  get<Type>(action: string, host:string='') {
    return this.http.get<Type>(this.makeurl(action, host), this.options);
  }

  post<Type, RType>(action: string, body:Type) {
    return this.http.post<RType>(this.makeurl(action), body,
        this.options);
  }

  put<Type>(action:string, body:Type) {
    return this.http.put<Type>(this.makeurl(action), body,
        this.options);
  }

  delete<Type>(action:string) {
    return this.http.delete<Type>(this.makeurl(action),
        this.options);
  }

  makeurl(action:string, host:string='') {
    if (host) {
        return host + '/' + action;
    }

    return this.endpoint + action;
  }
}
