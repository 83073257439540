import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

interface MenuItem {
  label: string;
  route: string;
}

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatToolbarModule
  ],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent {
  protected items: MenuItem[] = [
    {
      'label': 'Quotes',
      'route': '/quotes'
    }
  ]
  closed=false;
  group: string = '';
  rootmode:boolean = false;
  adminmode: boolean = false;

  constructor() {}

  ngOnInit() {
    // this.group = this.userprofile.groups[0];
    this.group = 'root';
    if (this.group == 'root') {
        this.rootmode = true;
    }
    if (this.group == 'admin') {
        this.adminmode = true;
    }
  }

  togglemenu() {
    this.closed = !this.closed;
  }

  getnavclass() {
    return this.closed ? "nav-closed" : "";
  }
}
