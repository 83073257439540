import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Customer, ICustomerListView } from '../shared/models';
import { CustomerService } from '../shared/customer.service';

@Component({
  selector: 'app-customer-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    MatIconModule
  ],
  templateUrl: './customer-list.component.html',
  styleUrl: './customer-list.component.scss'
})
export class CustomerListComponent {
  private service: CustomerService = inject(CustomerService);
  customers: ICustomerListView[] = [];

  ngOnInit() {
    this.service.listviewcustomers().subscribe(customers => {
      this.customers = customers;
    });
  }
}
