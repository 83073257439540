import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { PartAccessoryFormGroupComponent } from "../forms/part-accessory-form-group/part-accessory-form-group.component";

export interface IManufacturer {
  manufacturer_id: string;
  manufacturer_name: string;
}

export interface IPartGroup {
  group_name: string;
}

export interface IAccessory {
  accessory_id: string;
  part_id: string;
  accessory_ref: string;
  accessory_description: string;
}

export interface IMarkup {
  part_id: string;
  markup_number: number;
  markup: number;
}

export interface IPartNote {
  part_note_id: string;
  part_id: string;
  note: string;
}

export interface ISupplier {
  supplier_id: string;
  supplier_name: string;
}

export interface IPartSupplier {
  part_id: string;
  supplier_number: number;
  supplier_id: string;
}

export interface IPart {
  part_id?: string;
  part_ref: string;
  part_order_ref: string;
  part_description: string;
  part_price: number;
  part_is_panel_mount: boolean;
  part_group_ref: string;
  part_manufacturer_id: string;
  part_manufacturer_ref: string;
  accessories: IAccessory[];
  markups: IMarkup[];
  notes: IPartNote[];
  suppliers: IPartSupplier[];
  part_last_modified: string;
}

export interface IPartConfiguration {
  part_configuration_sequence_id?: string;
  part_id: string;
  configuration_option_id: string;
};

export interface IPartAccessoryFormGroup {
  accessory_id: FormControl<string>;
  part_id: FormControl<string>;
  accessory_ref: FormControl<string>;
  accessory_description: FormControl<string>;
}

export interface IPartMarkupFormGroup {
  part_id: FormControl<string>;
  markup_number: FormControl<number>;
  markup: FormControl<number>;
}

export interface IPartNoteFormGroup {
  part_note_id: FormControl<string>;
  part_id: FormControl<string>;
  note: FormControl<string>;
}

export interface IPartSupplierFormGroup {
  part_id: FormControl<string>;
  supplier_number: FormControl<number>;
  supplier_id: FormControl<string>;
}

export interface IPartFormGroup {
  part_id?: FormControl<string>;
  part_ref: FormControl<string>;
  part_order_ref: FormControl<string>;
  part_description: FormControl<string>;
  part_price: FormControl<number>;
  part_is_panel_mount: FormControl<boolean>;
  part_group_ref: FormControl<string>;
  part_manufacturer_id: FormControl<string>;
  part_manufacturer_ref: FormControl<string>;
  accessories: FormArray<FormGroup<IPartAccessoryFormGroup>>;
  markups: FormArray<FormGroup<IPartMarkupFormGroup>>;
  notes: FormArray<FormGroup<IPartNoteFormGroup>>;
  suppliers: FormArray<FormGroup<IPartSupplierFormGroup>>;
}

export interface IComposite {
  composite_id?: string;
  composite_reference: string;
  composite_description: string;
  composite_parts: ICompositePart[];
  composite_last_modified: string;
}

export interface ICompositePart {
  subassembly_sequence_id: string;
  composite_id: string;
  part_id: string;
  quantity: number;
  position: number;
  description?: string;
}

export interface ICompositeFormGroup {
  composite_id: FormControl<string>;
  composite_reference: FormControl<string>;
  composite_description: FormControl<string>;
  composite_parts: FormArray<FormGroup<ICompositePartFormGroup>>
}

export interface ICompositePartFormGroup {
  subassembly_sequence_id: FormControl<string>;
  composite_id: FormControl<string>;
  part_id: FormControl<string>;
  quantity: FormControl<number>;
  position: FormControl<number>;
}

export class Part implements IPart {
  part_id: string;
  part_ref: string = '';
  part_order_ref: string = '';
  part_description: string = '';
  part_price: number = 0;
  part_is_panel_mount: boolean = false;
  part_group_ref: string = '';
  part_manufacturer_id: string = '';
  part_manufacturer_ref: string = '';
  accessories: PartAccessory[] = [];
  markups: PartMarkup[] = [];
  notes: PartNote[] = [];
  suppliers: PartSupplier[] = [];
  part_last_modified: string = '';

  constructor(part: IPart | undefined = undefined) {
    this.part_id = part?.part_id || '';
    this.part_ref = part?.part_ref || '';
    this.part_order_ref = part?.part_order_ref || '';
    this.part_description = part?.part_description || '';
    this.part_price = part?.part_price || 0;
    this.part_is_panel_mount = part?.part_is_panel_mount || false;
    this.part_group_ref = part?.part_group_ref || '';
    this.part_manufacturer_id = part?.part_manufacturer_id || '';
    this.part_manufacturer_ref = part?.part_manufacturer_ref || '';
    this.part_last_modified = part?.part_last_modified || '';

    if (part?.markups && part?.markups.length) {
      for (let markup of part.markups) {
        this.addMarkup(markup);
      }
    }

    if (part?.accessories && part?.accessories.length) {
      for (let accessory of part.accessories) {
        this.addAccessory(accessory);
      }
    }

    if (part?.notes && part?.notes.length) {
      for (let note of part.notes) {
        this.addNote(note);
      }
    }

    if (part?.suppliers && part?.suppliers.length) {
      for (let supplier of part.suppliers) {
        this.addSupplier(supplier);
      }
    }
  }

  addMarkup(markup: IMarkup | undefined = undefined): void {
        this.markups.push(new PartMarkup(markup));
  }

  addAccessory(accessory: IAccessory | undefined = undefined): void {
        this.accessories.push(new PartAccessory(accessory));
  }

  addNote(note: IPartNote | undefined = undefined): void {
        this.notes.push(new PartNote(note));
  }

  addSupplier(supplier: IPartSupplier | undefined = undefined):void {
        this.suppliers.push(new PartSupplier(supplier));
  }

  get formGroup(): FormGroup<IPartFormGroup> {
    let fg: FormGroup<IPartFormGroup> = new FormGroup<IPartFormGroup>({
      part_id: new FormControl(this.part_id || '', {nonNullable: true}),
      part_ref: new FormControl(this.part_ref || '', {nonNullable: true}),
      part_order_ref: new FormControl(this.part_order_ref || '', {nonNullable: true}),
      part_description: new FormControl(this.part_description || '', {nonNullable: true}),
      part_price: new FormControl(this.part_price || 0, {nonNullable: true}),
      part_is_panel_mount: new FormControl(this.part_is_panel_mount || false, {nonNullable: true}),
      part_group_ref: new FormControl(this.part_group_ref || '', {nonNullable: true}),
      part_manufacturer_id: new FormControl(this.part_manufacturer_id || '', {nonNullable: true}),
      part_manufacturer_ref: new FormControl(this.part_manufacturer_ref || '', {nonNullable: true}),
      markups: new FormArray<FormGroup<IPartMarkupFormGroup>>([]),
      accessories: new FormArray<FormGroup<IPartAccessoryFormGroup>>([]),
      suppliers: new FormArray<FormGroup<IPartSupplierFormGroup>>([]),
      notes: new FormArray<FormGroup<IPartNoteFormGroup>>([])
    });

    /*if (this.markups.length) {
      for (let markup of this.markups) {
        fg.controls.markups.controls.push(markup.formGroup);
      }
    }

    if (this.notes.length) {
      for (let note of this.notes) {
        fg.controls.notes.controls.push(note.formGroup);
      }
    }

    if (this.suppliers.length) {
      for (let supplier of this.suppliers) {
        fg.controls.suppliers.controls.push(supplier.formGroup);
      }
    }*/

    return fg;
  }
}

export class Composite implements IComposite {
  composite_id: string = '';
  composite_reference: string = '';
  composite_description: string = '';
  composite_parts: CompositePart[] = [];
  composite_last_modified: string = '';

  constructor(composite: IComposite | undefined = undefined) {
    this.composite_id = composite?.composite_id || '';
    this.composite_reference = composite?.composite_reference || '';
    this.composite_description = composite?.composite_description || '';
    this.composite_parts = [];
    this.composite_last_modified = composite?.composite_last_modified || '';

    if (composite?.composite_parts && composite.composite_parts.length) {
      for (let compositePart of composite.composite_parts) {
        this.composite_parts.push(new CompositePart(compositePart));
      }
    }
  }

  get formGroup(): FormGroup<ICompositeFormGroup> {
    let fg = new FormGroup<ICompositeFormGroup>({
      composite_id: new FormControl<string>(this.composite_id, {nonNullable: true}),
      composite_reference: new FormControl<string>(this.composite_reference, {nonNullable: true}),
      composite_description: new FormControl<string>(this.composite_description, {nonNullable: true}),
      composite_parts: new FormArray<FormGroup<ICompositePartFormGroup>>([])
    });

    /*if (this.parts && this.parts.length) {
      for (let comp_part of this.parts) {
        fg.controls.composite_parts.push(comp_part.formGroup);
      }
    }*/

    return fg;
  }
}

export class CompositePart implements ICompositePart {
  subassembly_sequence_id: string = '';
  composite_id: string = '';
  part_id: string = '';
  quantity: number = 0;
  position: number = 0;
  description?: string = '';

  constructor(compositePart: ICompositePart | undefined = undefined) {
    this.subassembly_sequence_id = compositePart?.subassembly_sequence_id || '';
    this.composite_id = compositePart?.composite_id || '';
    this.part_id = compositePart?.part_id || '';
    this.quantity = compositePart?.quantity || 0;
    this.position = compositePart?.position || 0;
    this.description = compositePart?.description || '';
  }

  get formGroup(): FormGroup<ICompositePartFormGroup> {
    return new FormGroup<ICompositePartFormGroup>({
      subassembly_sequence_id: new FormControl(this.subassembly_sequence_id, {nonNullable: true}),
      composite_id: new FormControl(this.composite_id, {nonNullable: true}),
      part_id: new FormControl(this.part_id, {nonNullable: true}),
      quantity: new FormControl<number>(this.quantity, {nonNullable: true}),
      position: new FormControl<number>(this.position, {nonNullable: true}),
    });
  }
}

export class PartMarkup implements IMarkup {
  part_id: string = '';
  markup_number: number = 0;
  markup: number = 0;

  constructor(markup: IMarkup | undefined = undefined) {
    this.part_id = markup?.part_id || '';
    this.markup_number = markup?.markup_number || 1;
    this.markup = markup?.markup || 0;
  }

  get formGroup(): FormGroup<IPartMarkupFormGroup> {
    return new FormGroup({
      'part_id': new FormControl(this.part_id, {nonNullable: true}),
      'markup_number': new FormControl<number>(this.markup_number, {nonNullable: true}),
      'markup': new FormControl<number>(this.markup, {nonNullable: true})
    });
  }
}

export class PartNote implements IPartNote {
  part_note_id: string = '';
  part_id: string = '';
  note: string = '';

  constructor(note: IPartNote | undefined = undefined) {
    this.part_note_id = note?.part_note_id || '';
    this.part_id = note?.part_id || '';
    this.note = note?.note || '';
  }

  get formGroup(): FormGroup<IPartNoteFormGroup> {
    return new FormGroup({
      part_note_id: new FormControl(this.part_note_id, {nonNullable: true}),
      part_id: new FormControl(this.part_id, {nonNullable: true}),
      note: new FormControl(this.note, {nonNullable: true})
    });
  }
}

export class PartAccessory implements IAccessory {
  accessory_id: string = '';
  part_id: string = '';
  accessory_ref: string = '';
  accessory_description: string = '';

  constructor(accessory: IAccessory | undefined = undefined) {
    this.accessory_id = accessory?.accessory_id || '';
    this.part_id = accessory?.part_id || '';
    this.accessory_ref = accessory?.accessory_ref || '';
    this.accessory_description = accessory?.accessory_description || '';
  }

  get formGroup(): FormGroup<IPartAccessoryFormGroup> {
    return new FormGroup({
      'accessory_id': new FormControl(this.accessory_id || '', {nonNullable: true}),
      'part_id': new FormControl(this.part_id || '', {nonNullable: true}),
      'accessory_ref': new FormControl(this.accessory_ref || '', {nonNullable: true}),
      'accessory_description': new FormControl(this.accessory_description || '', {nonNullable: true})
    });
  }
}

export class PartSupplier implements IPartSupplier {
  part_id: string = '';
  supplier_number: number = 1;
  supplier_id: string = '';

  constructor(partSupplier: IPartSupplier | undefined = undefined) {
    this.part_id = partSupplier?.part_id || '';
    this.supplier_number = partSupplier?.supplier_number || 1;
    this.supplier_id = partSupplier?.supplier_id || '';
  }

  get formGroup(): FormGroup<IPartSupplierFormGroup> {
    return new FormGroup({
      part_id: new FormControl(this.part_id, {nonNullable: true}),
      supplier_number: new FormControl(this.supplier_number, {nonNullable: true}),
      supplier_id: new FormControl(this.supplier_id, {nonNullable: true})
    });
  }
}
