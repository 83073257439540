import { Component } from '@angular/core';
import { PartHeaderComponent } from '../parts/part-header/part-header.component';

@Component({
  selector: 'app-manufacturer',
  standalone: true,
  imports: [
    PartHeaderComponent
  ],
  templateUrl: './manufacturer.component.html',
  styleUrl: './manufacturer.component.scss'
})
export class ManufacturerComponent {

}
