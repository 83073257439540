<div id="root">
<section class="content-wrapper">
    <main [attr.style]="mobile ? 'min-height:' + screenheight + 'px' : ''">
        <article class="login-form-container">
            <div id="login-form-outer">
                <div class="form-row">
                    <div id="logo">
                      <div id="logo-inner">
                      </div>
                    </div>
                    <div class="form-wrapper">
                        <form id="login-form" [formGroup]="loginForm" (submit)="authenticate()">
                            <div id="fieldset">
                                <mat-form-field class="full-width">
                                    <mat-label>Username</mat-label>
                                    <input type="text" matInput formControlName="username"/>
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                    <mat-label>Password</mat-label>
                                    <input type="password" matInput formControlName="password" />
                                </mat-form-field>
                                <!--<div class="full-width right-content">
                                    <a href="#">Forgotten your password?</a>
                                </div>-->
                                <div class="form-action full-width">
                                    <button mat-raised-button color="primary">Log In</button>
                                </div>
                            </div>
                            <div class="login-error" *ngIf="loginError">
                                <p>{{ loginErrorMsg }}</p>
                            </div>
                            <!--<div *ngIf="mobile" class="full-width mb-acct">
                                <p>Need an account?  <a class="call-to-action" href="#">Sign up</a></p>
                            </div>-->
                        </form>
                    </div>
                </div>
                <!--<div *ngIf="!mobile" class="form-row">
                    <p>Need an account?  <a class="call-to-action" href="#">Sign up</a></p>
                </div>-->
            </div>
        </article>
        <div class="hypergraph">
            <span class="from-hyper"></span>
      </div>
    </main>
    <footer>
        <div>
            <div>
                <div class="floating-footer">
                    <div class="floating-footer-item">&copy; {{ today | date:'yyyy' }} TF Tull Ltd</div>
                </div>
            </div>
        </div>
    </footer>
</section>
</div>
