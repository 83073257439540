<form [formGroup]="partFormGroup" class="data-form" *ngIf="partFormGroup" (submit)="editPart()">
  <mat-form-field>
    <mat-label>Part Reference</mat-label>
    <input matInput type="text" formControlName="part_ref" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Part Order Reference</mat-label>
    <input matInput type="text" formControlName="part_order_ref" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <textarea matInput type="text" formControlName="part_description"></textarea>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Unit Price</mat-label>
    <input matInput type="text" formControlName="part_price" />
  </mat-form-field>
  <app-select-part-group-control
    formControlName="part_group_ref">
  </app-select-part-group-control>
  <app-select-manufacturer-control
    formControlName="part_manufacturer_id">
  </app-select-manufacturer-control>
  <mat-form-field>
    <mat-label>Part Manufacturer Reference</mat-label>
    <input matInput type="text" formControlName="part_manufacturer_ref" />
  </mat-form-field>
  <div class="field-row">
    <mat-checkbox formControlName="part_is_panel_mount">Panel Mount?</mat-checkbox>
  </div>
  <div id="markup" class="multi-field">
    <div class="data-form-section-header data-form-toolbar">
      <h4>Part Markup</h4>
      <div class="action-panel">
        <button mat-icon-button type="button" (click)="addMarkup()"><mat-icon>add</mat-icon></button>
      </div>
    </div>
    <ng-container fromArrayName="markups">
    <div class="multi-field-row" *ngFor="let fg of markups.controls; let i = index">
      <div class="multi-field-row-item" [formGroup]="fg">
      <mat-form-field>
        <mat-label>Markup</mat-label>
          <input matInput formControlName="markup" type="number" />
        </mat-form-field>
      </div>
      <div class="multi-field-row-action">
        <button type="button" mat-icon-button (click)="deleteMarkupAt(i)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
    </ng-container>
  </div>
  <div id="accessory" class="multi-field">
    <div class="data-form-section-header data-form-toolbar">
      <h4>Part Accessories</h4>
      <div class="action-panel">
        <button mat-icon-button type="button" (click)="addAccessory()"><mat-icon>add</mat-icon></button>
      </div>
    </div>
    <div class="multi-field-row" *ngFor="let fg of accessories.controls; let i = index">
      <div class="multi-field-row-item">
        <app-part-accessory-form-group [fg]="fg"></app-part-accessory-form-group>
      </div>
      <div class="multi-field-row-action">
        <button type="button" mat-icon-button (click)="deleteAt(accessories, i)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
  </div>
  <div id="notes" class="multi-field">
    <div class="data-form-section-header data-form-toolbar">
      <h4>Part Notes</h4>
      <div class="action-panel">
        <button mat-icon-button type="button" (click)="addNote()"><mat-icon>add</mat-icon></button>
      </div>
    </div>
    <div class="multi-field-row" *ngFor="let fg of notes.controls; let i = index">
      <div class="multi-field-row-item">
        <app-part-note-form-group [fg]="fg"></app-part-note-form-group>
      </div>
      <div class="multi-field-row-action">
        <button type="button" mat-icon-button (click)="deleteAt(notes, i)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
  </div>
  <div id="suppliers" class="multi-field">
    <div class="data-form-section-header data-form-toolbar">
      <h4> Part Suppliers</h4>
      <div class="action-panel">
        <button mat-icon-button type="button" (click)="addSupplier()"><mat-icon>add</mat-icon></button>
      </div>
    </div>
    <div *ngFor="let fg of suppliers.controls; let i = index" class="multi-field-row">
      <div class="multi-field-row-item" [formGroup]="fg">
        <app-select-supplier-control formControlName="supplier_id"></app-select-supplier-control>
      </div>
      <div class="multi-field-row-action">
        <button type="button" mat-icon-button (click)="deleteAt(suppliers, i)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
  </div>
  <div class="form-action form-action-right">
    <button mat-raised-button>Edit</button>
  </div>
</form>
