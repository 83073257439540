<app-user-header></app-user-header>
<div class="hyp-list">
  <div id="user-header" class="hyp-list-row">
    <h4 id="username" class="hyp-list-item">Username</h4>
    <h4 id="name" class="hyp-list-item">Name</h4>
    <h4 id="email" class="hyp-list-item">Email</h4>
    <h4 id="group" class="hyp-list-item">Group</h4>
    <span class="hyp-action hyp-list-item"></span>
  </div>

  <div *ngFor="let user of users" class="hyp-list-row">
    <span id="username" class="hyp-list-item">{{ user.username }}</span>
    <span id="name" class="hyp-list-item">{{ user.first_name }} {{ user.surname}}</span>
    <span id="email" class="hyp-list-item">{{ user.email }}</span>
    <span id="group" class="hyp-list-item">{{ user.group }}</span>
    <div class="hyp-action hyp-list-item">
      <a mat-icon-button [routerLink]="['/users', user.username]"><mat-icon>edit</mat-icon></a>
    </div>
  </div>
</div>
