import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IQuoteSummary } from '../../plm/quotes/shared/interfaces';

@Component({
  selector: 'app-summary-table',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './summary-table.component.html',
  styleUrl: './summary-table.component.scss'
})
export class SummaryTableComponent {
  @Input() summary!: IQuoteSummary[];
}
