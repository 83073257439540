import { FormArray, FormControl, FormGroup, FormGroupName, Validators } from "@angular/forms";

export interface IAddress {
  address_id?: string;
  address_line1: string;
  address_line2: string;
  address_line3: string;
  city: string;
  county: string;
  postal_code: string;
  is_primary: boolean;
}

export interface AddressFormGroup {
  address_id: FormControl<string>;
  address_line1: FormControl<string>;
  address_line2: FormControl<string>;
  address_line3: FormControl<string>;
  city: FormControl<string>;
  county: FormControl<string>;
  postal_code: FormControl<string>;
  is_primary: FormControl<boolean>;
}

export class Address implements IAddress {
  address_id: string = '';
  address_line1: string = '';
  address_line2: string = '';
  address_line3: string = '';
  city: string = '';
  county: string = '';
  postal_code: string = '';
  is_primary: boolean = false;

  constructor(address: IAddress | undefined = undefined) {
    this.address_id = address?.address_id || '';
    this.address_line1 = address?.address_line1 || '';
    this.address_line2 = address?.address_line2 || '';
    this.address_line3 = address?.address_line3 || '';
    this.city = address?.city || '';
    this.county = address?.county || '';
    this.postal_code = address?.postal_code || '';
    this.is_primary = address?.is_primary || false;
  }

  get formGroup(): FormGroup<AddressFormGroup> {
    return new FormGroup<AddressFormGroup>({
      "address_id": new FormControl(this.address_id || '', {nonNullable: true}),
      "address_line1": new FormControl(this.address_line1 || '', {nonNullable: true, validators: [Validators.required]}),
      "address_line2": new FormControl(this.address_line2 || '', {nonNullable: true}),
      "address_line3": new FormControl(this.address_line3 || '', {nonNullable: true}),
      "city": new FormControl(this.city || '', {nonNullable: true, validators: [Validators.required]}),
      "county": new FormControl(this.county || '', {nonNullable: true, validators: [Validators.required]}),
      "postal_code": new FormControl(this.postal_code || '', {nonNullable: true, validators: [Validators.required]}),
      "is_primary": new FormControl(this.is_primary || false, {nonNullable: true})
    });
  }
}

