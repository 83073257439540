import { Component, inject } from '@angular/core';
import { Customer, CustomerFormGroup } from '../shared/models';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { AddressFormGroup } from '../../controls/shared/models';
import { AddressComponent } from '../../controls/address/address.component';
import { CustomerService } from '../shared/customer.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    AddressComponent
  ],
  templateUrl: './customer-edit.component.html',
  styleUrl: './customer-edit.component.scss'
})
export class CustomerEditComponent {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private service: CustomerService = inject(CustomerService);
  private customer_id: string;

  private customer!: Customer;
  customerFormGroup!: FormGroup<CustomerFormGroup>;
  editMode: boolean = false;
  buttonText: string = 'Add';

  get customer_name() {
    return this.customerFormGroup.controls.customer_name;
  }

  get addresses(): FormArray<FormGroup<AddressFormGroup>> {
    return this.customerFormGroup.controls.address as FormArray<FormGroup<AddressFormGroup>>;
  }

  constructor() {
    this.customer_id = this.route.snapshot.params['id'] || undefined;
  }

  ngOnInit() {
    this.customer = new Customer;
    this.customerFormGroup = this.customer.formGroup;

    if (this.customer_id) {
      this.service.getcustomer(this.customer_id).subscribe(customer => {
        this.customer = new Customer(customer);
        this.customerFormGroup = this.customer.formGroup;
        this.addAddress();
        this.setEditMode();
      });
    } else {
      this.addAddress();
    }
  }

  addAddress() {
    for (let address of this.customer.address) {
      this.customerFormGroup.controls.address.push(address.formGroup);
    }
  }

  setEditMode() {
    this.editMode = true;
    this.buttonText = 'Edit';
  }

  editCustomer() {
    for (let control of this.addresses.controls) {
      console.log(control.controls.address_id.valid);
      console.log(control.controls.city.valid);
    }
    if (this.customerFormGroup.valid) {
      if (this.editMode) {
        this.service.updateCustomer(this.customerFormGroup.value as Customer).subscribe(msg => {
          console.log(msg);
        });
      } else {
        this.service.createCustomer(this.customerFormGroup.value as Customer).subscribe(msg => {
          this.setEditMode();
        });
      }
    }
  }
}
