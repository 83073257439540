import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { UserService } from '../../core/user.service';
import { User } from '../shared/models';
import { UserHeaderComponent } from '../user-header/user-header.component';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    UserHeaderComponent
  ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent {
  private service: UserService = inject(UserService);

  users: User[] = [];

  ngOnInit() {
    this.service.getUsers().subscribe(users => {
      this.users = users;
    });
  }
}
